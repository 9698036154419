import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Card,
  Divider,
  Alert,
  Select,
  MenuItem,
  Grid2,
  FormControl,
  InputLabel,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Chip,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { adminUnitsData } from "../../assets/data/data";
import { Delete } from "@mui/icons-material";

const marketTypes = ["Trading Center", "Town", "Municipality"];

const DataAdminUnits = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [creating, setCreating] = useState(false);
  const [editing, setEditing] = useState(false);
  const [editUnitId, setEditUnitId] = useState(null);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [newAdminUnit, setNewAdminUnit] = useState({
    name: "",
    subCounty: "",
    ward: "",
    latitude: 0,
    longitude: 0,
    type: "",
  });
  const [wards, setWards] = useState([]);
  const [column, setColumn] = useState("name");
  const [searchValue, setSearchValue] = useState("");
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [loading, setLoading] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);

  useEffect(() => {
    fetchAdminUnits();
  }, [paginationModel, searchValue]);

  const fetchAdminUnits = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `/api/adminunits?offset=${
          paginationModel.page * paginationModel.pageSize
        }&limit=${paginationModel.pageSize}${
          searchValue ? `&${column}=${searchValue}` : ""
        }`
      );
      const data = await response.json();
      if (response.ok) {
        setData(data);
      }
    } catch (error) {
      console.error("Error fetching admin units:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    setPaginationModel({ ...paginationModel, page: 0 });
  };

  const handleOpenUploadDialog = (unit = null) => {
    setEditing(!!unit);
    setEditUnitId(unit?.id || null);
    if (unit) {
      setNewAdminUnit({ ...unit });
      setWards(adminUnitsData[unit.subCounty] || []);
    } else {
      setNewAdminUnit({
        name: "",
        subCounty: "",
        ward: "",
        latitude: 0,
        longitude: 0,
        type: "",
      });
      setWards([]);
    }
    setOpenUploadDialog(true);
  };

  const handleCloseUploadDialog = () => {
    setOpenUploadDialog(false);
    setError("");
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setNewAdminUnit((prev) => {
      const updatedUnit = {
        ...prev,
        [name]: value,
      };

      if (name === "subCounty") {
        const newWards = adminUnitsData[value] || [];
        if (!newWards.includes(prev.ward)) {
          updatedUnit.ward = "";
        }
        setWards(newWards);
      }

      return updatedUnit;
    });
  };

  const handleCreateAdminUnit = async () => {
    try {
      setError("");
      setCreating(true);
      const response = await fetch("/api/adminunits", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newAdminUnit),
      });

      const body = await response.json();
      if (response.ok) {
        setError(body.message);
        setTimeout(() => {
          fetchAdminUnits();
          handleCloseUploadDialog();
        }, 1000);
      } else {
        setError(body.message);
      }
    } catch (error) {
      setError("Failed to create admin unit. Please try again.");
    } finally {
      setCreating(false);
    }
  };

  const handleEditAdminUnit = async () => {
    try {
      setError("");
      setCreating(true);
      const response = await fetch(`/api/adminunits/${editUnitId}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newAdminUnit),
      });

      const body = await response.json();
      if (response.ok) {
        setError(body.message);
        setTimeout(() => {
          fetchAdminUnits();
          handleCloseUploadDialog();
        }, 1000);
      } else {
        setError(body.message);
      }
    } catch (error) {
      setError("Failed to update admin unit. Please try again.");
    } finally {
      setCreating(false);
    }
  };

  const handleDeleteAdminUnit = async (id) => {
    try {
      setError("");
      const response = await fetch(`/api/adminunits/${id}`, {
        method: "DELETE",
      });
      const body = await response.json();
      if (response.ok) {
        setError(body.message);
        setTimeout(() => {
          fetchAdminUnits();
          setOpenDetailsDialog(false);
        }, 1000);
      } else {
        setError(body.message);
      }
    } catch (error) {
      setError("Failed to delete admin unit. Please try again.");
    }
  };

  const columns = [
    {
      field: "index",
      headerName: "No",
      renderCell: (params) => {
        const index = data
          ? data.data.map((e) => e.id).indexOf(params?.row?.id)
          : 0;
        return (
          <Chip
            label={index + 1 + paginationModel.page * paginationModel.pageSize}
          />
        );
      },
    },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "subCounty", headerName: "Sub County", flex: 1 },
    { field: "ward", headerName: "Ward", flex: 1 },
    { field: "latitude", headerName: "Latitude", flex: 1 },
    { field: "longitude", headerName: "Longitude", flex: 1 },
    { field: "type", headerName: "Type", flex: 1 },
  ];

  const handleRowClick = (params) => {
    setSelectedUnit(params.row);
    setOpenDetailsDialog(true);
  };

  return (
    <Box>
      <Card sx={{ boxShadow: "0px 8px 32px #60606040", p: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2, gap: 2 }}>
          <Box flexGrow={1}></Box>
          <FormControl>
            <InputLabel size="small">Search by...</InputLabel>
            <Select
              label="Search by..."
              size="small"
              onChange={(e) => setColumn(e.target.value)}
              value={column}
            >
              <MenuItem value="name">Name</MenuItem>
              <MenuItem value="subCounty">Sub County</MenuItem>
              <MenuItem value="ward">Ward</MenuItem>
            </Select>
          </FormControl>
          <TextField
            variant="outlined"
            size="small"
            label="Search..."
            value={searchValue}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => handleOpenUploadDialog(null)}
            sx={{ color: "white", textTransform: "capitalize" }}
          >
            Create New
          </Button>
        </Box>

        <DataGrid
          rows={data?.data || []}
          columns={columns}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[5, 10, 25, 50]}
          rowCount={data?.total || 0}
          loading={loading}
          paginationMode="server"
          autoHeight
          onRowClick={handleRowClick}
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Card>

      <Dialog
        fullWidth
        open={openUploadDialog}
        onClose={handleCloseUploadDialog}
      >
        <DialogTitle>
          {editing ? "Edit Admin Unit" : "Create Admin Unit"}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              label="Name"
              name="name"
              value={newAdminUnit.name}
              onChange={handleInputChange}
              fullWidth
              required
              margin="dense"
            />
            <FormControl fullWidth>
              <InputLabel>Type</InputLabel>
              <Select
                label="Type"
                name="type"
                value={newAdminUnit.type}
                onChange={handleInputChange}
                required
              >
                {marketTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Grid2 container spacing={2}>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <FormControl fullWidth>
                  <InputLabel>Sub County</InputLabel>
                  <Select
                    label="Sub County"
                    name="subCounty"
                    value={newAdminUnit.subCounty}
                    onChange={handleInputChange}
                    required
                  >
                    {Object.keys(adminUnitsData).map((subCounty) => (
                      <MenuItem key={subCounty} value={subCounty}>
                        {subCounty}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <FormControl fullWidth>
                  <InputLabel>Ward</InputLabel>
                  <Select
                    label="Ward"
                    name="ward"
                    value={newAdminUnit.ward}
                    onChange={handleInputChange}
                    required
                  >
                    {wards.map((ward) => (
                      <MenuItem key={ward} value={ward}>
                        {ward}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid2>
            </Grid2>
            <Grid2 container spacing={2}>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <TextField
                  label="Latitude"
                  type="number"
                  name="latitude"
                  value={newAdminUnit.latitude}
                  onChange={handleInputChange}
                  fullWidth
                  required
                  margin="dense"
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <TextField
                  label="Longitude"
                  type="number"
                  name="longitude"
                  value={newAdminUnit.longitude}
                  onChange={handleInputChange}
                  fullWidth
                  required
                  margin="dense"
                />
              </Grid2>
            </Grid2>

            {error && (
              <Alert color={error.includes("success") ? "success" : "warning"}>
                {error}
              </Alert>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleCloseUploadDialog}>
            Cancel
          </Button>
          <Button
            onClick={editing ? handleEditAdminUnit : handleCreateAdminUnit}
            disabled={creating}
            color="primary"
            variant="contained"
          >
            {creating ? "Processing..." : "Save"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDetailsDialog}
        onClose={() => setOpenDetailsDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            px: 2,
            pt: 2,
            mb: 2,
            gap: 2,
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">Admin Unit Details</Typography>
          <Button
            variant="outlined"
            onClick={() => setOpenDetailsDialog(false)}
          >
            Close
          </Button>
        </Box>
        <Divider />
        <DialogContent>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableBody>
                {selectedUnit &&
                  Object.entries(selectedUnit).map(([key, value]) => (
                    <TableRow key={key}>
                      <TableCell
                        component="th"
                        scope="row"
                        padding="none"
                        sx={{ p: "8px" }}
                      >
                        <Typography variant="body2" fontWeight="bold">
                          {key.toUpperCase()}
                        </Typography>
                      </TableCell>
                      <TableCell padding="none" sx={{ p: "8px" }}>
                        {value}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleOpenUploadDialog(selectedUnit)}
            color="primary"
            variant="outlined"
            startIcon={<EditIcon />}
          >
            Edit
          </Button>
          <Button
            onClick={() => handleDeleteAdminUnit(selectedUnit.id)}
            color="error"
            variant="outlined"
            startIcon={<Delete />}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DataAdminUnits;
