import {
  Box,
  Chip,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Card,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

export default function Transactions() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [column, setColumn] = useState("title");
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  useEffect(() => {
    fetchAuditTrails();
  }, [paginationModel, searchValue]);

  const fetchAuditTrails = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `/api/audittrails?offset=${
          paginationModel.page * paginationModel.pageSize
        }&limit=${paginationModel.pageSize}&${column}=${searchValue}`
      );
      const data = await response.json();
      if (response.ok) {
        setData(data);
      }
    } catch (error) {
      console.error("Failed to fetch audit trails:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    setPaginationModel({ ...paginationModel, page: 0 });
  };

  const fetchAdminName = async (adminId) => {
    if (!adminId) return "System";
    try {
      const response = await fetch(`/api/admins/${adminId}`);
      const { name } = await response.json();
      return name || "Unknown";
    } catch (error) {
      console.error("Failed to fetch admin name:", error);
      return "Unknown";
    }
  };

  const handleRowClick = async (params) => {
    const adminName = await fetchAdminName(params?.row?.adminId);
    setSelectedRecord({ ...params?.row, adminName });
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedRecord(null);
  };

  const columns = [
    {
      field: "index",
      headerName: "No",
      renderCell: (params) => {
        const index = data
          ? data?.data?.map((e) => e.id).indexOf(params?.row?.id)
          : 0;
        return (
          <Chip
            label={index + 1 + paginationModel.page * paginationModel.pageSize}
          />
        );
      },
    },
    { field: "title", headerName: "Title", flex: 1.3 },

    { field: "table", headerName: "Table", flex: 0.7 },
    {
      field: "adminId",
      headerName: "Modified By",
      flex: 1,
      valueGetter: (params) => (params?.value ? "Loading..." : "System"),
    },
    {
      field: "type",
      headerName: "Type",
      renderCell: (params) => (
        <Chip
          label={params.value}
          color={
            params.value === "Create"
              ? "success"
              : params.value === "Update"
              ? "warning"
              : "error"
          }
          size="small"
        />
      ),
    },
    {
      field: "createdAt",
      headerName: "Date Modified",
      flex: 1,
      valueGetter: (params) =>
        dayjs(params?.value).format("YYYY-MM-DD HH:mm:ss"),
    },
  ];

  return (
    <Box
      sx={{
        p: 2,
        borderRadius: 3,
        boxShadow: "0px 4px 20px #60606030",
      }}
      component={Card}
    >
      <Box display="flex" gap={2} mb={2}>
        <Typography variant="title">Audit Trails</Typography>
        <Box flexGrow={1} />
        <FormControl>
          <InputLabel size="small">Search by...</InputLabel>
          <Select
            label="Search by..."
            size="small"
            value={column}
            onChange={(e) => setColumn(e.target.value)}
          >
            <MenuItem value="title">Title</MenuItem>
            <MenuItem value="type">Type</MenuItem>
            <MenuItem value="table">Table</MenuItem>
          </Select>
        </FormControl>
        <TextField
          variant="outlined"
          size="small"
          label="Search..."
          value={searchValue}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </Box>
      <Divider />
      <DataGrid
        rows={data ? data.data : []}
        columns={columns}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[5, 10, 25]}
        rowCount={data ? data.total : 0}
        loading={loading}
        onRowClick={handleRowClick}
        paginationMode="server"
        autoHeight
        sx={{ mt: 2, borderRadius: "12px" }}
        slots={{
          toolbar: GridToolbar,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />

      {selectedRecord && (
        <Dialog
          open={dialogOpen}
          onClose={handleCloseDialog}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>Record Details</DialogTitle>
          <DialogContent>
            <Typography variant="subtitle1" gutterBottom>
              <strong>Title:</strong> {selectedRecord.title}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              <strong>Type:</strong> {selectedRecord.type}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              <strong>Table:</strong> {selectedRecord.table}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              <strong>Date Created:</strong>{" "}
              {new Date(selectedRecord.createdAt).toLocaleString()}
            </Typography>

            <Box sx={{ display: "flex", gap: 2 }}>
              <Box sx={{ flex: 1 }}>
                <Typography variant="subtitle1" gutterBottom>
                  Before Record
                </Typography>
                <DataGrid
                  rows={Object.entries(selectedRecord.beforeRecord || {}).map(
                    ([key, value], index) => ({
                      id: index,
                      attribute: key,
                      value: JSON.stringify(value),
                    })
                  )}
                  columns={[
                    { field: "attribute", headerName: "Attribute", flex: 1 },
                    { field: "value", headerName: "Value", flex: 2 },
                  ]}
                  density="compact"
                  autoHeight
                  pagination={false}
                  disableColumnFilter
                  disableColumnMenu
                  disableSelectionOnClick
                  sx={{ mt: 1 }}
                />
              </Box>
              <Box sx={{ flex: 1 }}>
                <Typography variant="subtitle1" gutterBottom>
                  After Record
                </Typography>
                <DataGrid
                  rows={Object.entries(selectedRecord.afterRecord || {}).map(
                    ([key, value], index) => ({
                      id: index,
                      attribute: key,
                      value: JSON.stringify(value),
                    })
                  )}
                  columns={[
                    { field: "attribute", headerName: "Attribute", flex: 1 },
                    { field: "value", headerName: "Value", flex: 2 },
                  ]}
                  autoHeight
                  density="compact"
                  pagination={false}
                  disableColumnFilter
                  disableColumnMenu
                  disableSelectionOnClick
                  sx={{ mt: 1 }}
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
}
