import { Circle as CircleStyle, Fill, Stroke, Style, Text } from "ol/style";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Map from "ol/Map";
import { useState, useRef, useEffect } from "react";
import { OSM, XYZ } from "ol/source";
import {
  ZoomToExtent,
  defaults as defaultControls,
  ScaleLine,
  FullScreen,
  Attribution,
  Rotate,
  ZoomSlider,
} from "ol/control";
import { Polygon, Circle as CircleGeom, Point } from "ol/geom";
import Overlay from "ol/Overlay";
import {
  Checkbox,
  Box,
  Typography,
  Card,
  Divider,
  Paper,
  ButtonGroup,
  Button,
  CircularProgress,
  Grid2,
} from "@mui/material";
import { Layers, Satellite, Terrain } from "@mui/icons-material";
import { Feature } from "ol";
import { useNavigate } from "react-router-dom";

export default function TownsMap() {
  const [map, setMap] = useState(null);
  const mapRef = useRef();
  mapRef.current = map;
  const popupRef = useRef();
  const [popupContent, setPopupContent] = useState(null);
  const [loadingLayers, setLoadingLayers] = useState([]);
  const [featureSelectEnabled, setFeatureSelectEnabled] = useState(true);
  const [activeBasemap, setActiveBasemap] = useState("OSM");
  const legendItems = [
    {
      label: "Subcounty Boundary",
      stroke: "#48CFCB",
      fill: "white",
      radius: 6,
      width: 2,
      table: "subcounties",
    },
    {
      label: "Ward Boundary",
      stroke: "#ED3EF7",
      fill: "white",
      radius: 4,
      width: 2,
      table: "wards",
    },
    {
      label: "Towns",
      stroke: "white",
      fill: "#0D7C66",
      radius: 8,
      width: 2,
      table: "Towns",
    },
  ];

  const toggleBasemap = (label) => {
    setActiveBasemap(label);
    basemaps.forEach((basemap) => {
      basemap.layer.setVisible(basemap.label === label);
    });
  };

  useEffect(() => {
    const initialMap = new Map({
      target: "map",
      layers: basemaps.map((b) => b.layer),
      view: new View({
        zoom: 10,
        projection: "EPSG:4326",
        center: [34.45684, -0.53283],
      }),
      controls: defaultControls().extend([
        new ScaleLine(),
        new FullScreen(),
        new Attribution(),
        new Rotate(),
        new ZoomSlider(),
        new ZoomToExtent({
          extent: [
            633401.3446711299475282, 9919002.521270414814353,
            693716.2872443984961137, 9961211.8638889770954847,
          ],
        }),
      ]),
    });
    setMap(initialMap);
    return () => {
      initialMap.setTarget(null);
    };
  }, []);

  useEffect(() => {
    if (map) {
      const popupOverlay = new Overlay({
        element: popupRef.current,
        positioning: "bottom-center",
        stopEvent: false,
      });
      map.addOverlay(popupOverlay);
      map.on("singleclick", function (event) {
        if (featureSelectEnabled) {
          const features = map.getFeaturesAtPixel(event.pixel);

          if (features.length > 0) {
            const properties = features[0].getProperties();
            console.log(properties);

            delete properties.geometry;
            delete properties.ID;
            delete properties.createdAt;
            delete properties.updatedAt;
            delete properties.User;
            delete properties.Coordinates;
            delete properties.Picture;

            if (properties?.RecTime != undefined) {
              properties.RecTime = properties.RecTime.split(" ")[0];
            }
            if (properties?.FRecTime != undefined) {
              properties.FRecTime = properties.FRecTime.split(" ")[0];
            }

            setPopupContent(properties);
            popupOverlay.setPosition(event.coordinate);
          } else {
            setPopupContent(null);
            popupOverlay.setPosition(undefined);
          }
        }
      });
    }
  }, [map, featureSelectEnabled]);

  useEffect(() => {
    if (map) {
      fetchAndDisplayTowns();
      fetchBoundaries();
    }
  }, [map]);

  const fetchAndDisplayTowns = async () => {
    try {
      const response = await fetch(`/api/adminUnits`);
      const data = await response.json();
      if (response.ok) {
        const townFeatures = [];
        console.log(data.data); // Check your data here

        data.data.forEach((item) => {
          // Create a point feature for each town using the coordinates in [longitude, latitude] format
          const pointFeature = new Feature({
            geometry: new Point([item.longitude, item.latitude]), // Correct order [longitude, latitude]
            ...item,
          });

          // Set style for each feature
          pointFeature.setStyle(
            new Style({
              image: new CircleStyle({
                radius: 8,
                fill: new Fill({ color: "#0D7C66" }), // Orange with 50% opacity
                stroke: new Stroke({ color: "white", width: 2 }),
              }),
              text: new Text({
                text: item?.name ?? "undefined", // Display regArea as label
                font: "16px Calibri,sans-serif",
                fill: new Fill({ color: "#0D7C66" }),
                overflow: true,
                offsetY: 16,
              }),
            })
          );

          // Add the point feature to the array
          townFeatures.push(pointFeature);
        });

        // Create a VectorSource with all town features
        const townVectorSource = new VectorSource({
          features: townFeatures,
        });

        // Create a VectorLayer and add it to the map
        const townVectorLayer = new VectorLayer({
          source: townVectorSource,
          title: "Towns",
        });

        // Add the town layer to the map
        mapRef.current.addLayer(townVectorLayer);

        // Optionally, fit the map view to the extent of all the features
        const extent = townVectorSource.getExtent();
        console.log(extent); // Logs the bounding extent of the town features

        mapRef.current.getView().fit(extent, {
          padding: [20, 20, 20, 20], // Optional padding around the features
          duration: 1000, // Smooth transition
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchBoundaries = async () => {
    try {
      const wards = await fetch(`/api/landparcels/boundaries?ward=true`);
      if (wards.ok) {
        const data = await wards.json();
        if (Array.isArray(data)) {
          const polygonFeatures = [];
          data.forEach((item) => {
            if (item.geom && item.geom.coordinates) {
              // Create polygon feature
              const polygonFeature = new Feature({
                geometry: new Polygon(item.geom.coordinates),
              });

              // Set style for the polygon feature
              polygonFeature.setStyle(
                new Style({
                  fill: new Fill({ color: "rgba(0, 128, 0, 0.4)" }),
                  stroke: new Stroke({ color: "#028e37", width: 1 }),
                })
              );

              // Set specific properties from item to polygonFeature, excluding objects
              Object.entries(item).forEach(([key, value]) => {
                if (typeof value !== "object") {
                  polygonFeature.set(key, value);
                }
              });
              // Add polygon feature to array
              polygonFeatures.push(polygonFeature);
            }
          });

          // Add the polygon layer
          const polygonVectorSource = new VectorSource({
            features: polygonFeatures,
          });

          const polygonVectorLayer = new VectorLayer({
            source: polygonVectorSource,
            title: "Plots",
          });

          mapRef.current.addLayer(polygonVectorLayer);
        }
      }
      const subcounties = await fetch(
        `/api/landparcels/boundaries?subcounty=true`
      );
      if (subcounties.ok) {
        const data = await subcounties.json();
        if (Array.isArray(data)) {
          const polygonFeatures = [];
          data.forEach((item) => {
            if (item.geom && item.geom.coordinates) {
              // Create polygon feature
              const polygonFeature = new Feature({
                geometry: new Polygon(item.geom.coordinates),
              });

              // Set style for the polygon feature
              polygonFeature.setStyle(
                new Style({
                  fill: new Fill({ color: "rgba(0, 128, 0, 0.4)" }),
                  stroke: new Stroke({ color: "#028e37", width: 1 }),
                })
              );

              // Set specific properties from item to polygonFeature, excluding objects
              Object.entries(item).forEach(([key, value]) => {
                if (typeof value !== "object") {
                  polygonFeature.set(key, value);
                }
              });
              // Add polygon feature to array
              polygonFeatures.push(polygonFeature);
            }
          });

          // Add the polygon layer
          const polygonVectorSource = new VectorSource({
            features: polygonFeatures,
          });

          const polygonVectorLayer = new VectorLayer({
            source: polygonVectorSource,
            title: "Plots",
          });

          mapRef.current.addLayer(polygonVectorLayer);
        }
      }
    } catch (error) {}
  };

  return (
    <Card
      sx={{
        boxShadow: "0px 4px 16px #60606040",
        position: "relative",
        p: 2,
        borderRadius: "8px",
      }}
    >
      <div id="map" style={{ width: "100%", height: "80vh" }} />
      <Box
        sx={{
          position: "absolute",
          bottom: "3.3em",
          left: "1.5em",
          zIndex: 12,
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <ButtonGroup orientation="vertical">
          {basemaps.map((basemap, index) => (
            <Button
              size="small"
              key={index}
              onClick={() => toggleBasemap(basemap.label)}
              variant={
                activeBasemap === basemap.label ? "contained" : "outlined"
              }
            >
              {basemap.icon}
            </Button>
          ))}
        </ButtonGroup>
      </Box>

      <Card
        sx={{
          position: "absolute",
          bottom: "2.5rem",
          backgroundColor: "rgba(255,255,255,0.7)",
          right: "1.5em",
          py: 1,
          px: 2,
          borderRadius: "8px",
        }}
      >
        <Typography variant="h6">Legend</Typography>
        <Divider sx={{ mb: "10px" }} />
        {legendItems.map((item, i) => (
          <LegendItem key={i} item={item} map={map} />
        ))}
      </Card>
      <Paper
        ref={popupRef}
        sx={{
          position: "absolute",
          backgroundColor: "white",
          padding: "10px",
          borderRadius: "8px",
          display: popupContent ? "block" : "none",
          zIndex: 10,
          transform: "translate(-50%, -100%)",
        }}
      >
        {popupContent && (
          <Popup popupContent={popupContent} popupRef={popupRef} />
        )}
      </Paper>

      <Box
        sx={{
          position: "absolute",
          top: "2.5rem",
          right: "1.5em",
          zIndex: 11,
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        {loadingLayers.map((label, index) => (
          <LoadingBar key={index} label={label} />
        ))}
      </Box>
    </Card>
  );
}

const basemaps = [
  {
    label: "OSM",
    layer: new TileLayer({
      source: new OSM(),
      title: "OSM",
      visible: true,
    }),
    icon: <Layers fontSize="small" />,
  },
  {
    label: "Satellite",
    layer: new TileLayer({
      source: new XYZ({
        url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/mapServer/tile/{z}/{y}/{x}", // Replace this with a proper Satellite tile URL
      }),
      title: "Satellite",
      visible: false,
    }),
    icon: <Satellite fontSize="small" />,
  },
  {
    label: "Terrain",
    layer: new TileLayer({
      source: new XYZ({
        url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/mapServer/tile/{z}/{y}/{x}", // OpenTopoMap
      }),
      title: "Terrain",
      visible: false,
    }),
    icon: <Terrain fontSize="small" />,
  },
];

const LegendItem = (props) => {
  const [showing, setShowing] = useState(true);

  useEffect(() => {
    if (props.map) {
      props.map.getLayers().forEach((layer) => {
        if (layer && layer.get("title") === props.item.label) {
          layer.setVisible(showing);
        }
      });
    }
  }, [showing, props.map, props.item.label]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        mb: "3px",
        gap: 1,
      }}
    >
      <Checkbox
        checked={showing}
        sx={{
          height: 10,
          width: 10,
        }}
        onChange={(e) => setShowing(e.target.checked)}
      />
      <Box
        sx={{
          border: `1px solid ${props.item.stroke}`,
          backgroundColor: props.item.fill,
          height: 16,
          width: 16,
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      />
      <Typography variant="body2">{props.item.label}</Typography>
    </Box>
  );
};

const LoadingBar = ({ label }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        padding: "8px",
        borderRadius: "8px",
        mb: 1,
      }}
    >
      <CircularProgress size={20} sx={{ mr: 1 }} />
      <Typography variant="body2">{label} is loading...</Typography>
    </Box>
  );
};

const Popup = ({ popupRef, popupContent }) => {
  const navigate = useNavigate();
  const [parcels, setParcels] = useState(0);
  const [billingData, setBillingData] = useState(null);

  const fetchBilling = async () => {
    try {
      const res = await fetch(`/api/billings/towns/${popupContent.id}`);
      if (res.ok) {
        const data = await res.json();
        console.log(data);
        setBillingData(data[0]);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const pres = await fetch(
          `/api/landparcels?limit=0&adminUnitId=${popupContent.id}`
        );
        if (pres.ok) {
          const data = await pres.json();
          console.log(data);

          setParcels(data.total);
        }
      } catch (error) {}
    };
    fetchCounts();
    fetchBilling();
  }, [popupContent]);

  return (
    <Paper
      ref={popupRef}
      sx={{
        position: "absolute",
        backgroundColor: "white",
        padding: "10px",
        borderRadius: "8px",
        display: popupContent ? "block" : "none",
        zIndex: 10,
        transform: "translate(-50%, -100%)",
        minWidth: { xs: "95vw", md: "45vw" },
      }}
    >
      <Typography variant="subtitle1">Billing</Typography>
      <Divider sx={{ mb: "5px" }} />
      <Grid2 container spacing={2} p={1}>
        <Grid2 size={{ xs: 12, md: 4 }}>
          <Card
            sx={{
              borderRadius: "8px",
              p: 1,
              boxShadow: "0px 4px 16px #60606030",
              height: "100%",
            }}
          >
            <Typography>Admin Unit</Typography>
            <Divider sx={{ my: 1 }} />
            <Typography variant="body2" gutterBottom>
              <span style={{ color: "gray" }}>Name: </span> {popupContent.name}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <span style={{ color: "gray" }}>SubCounty: </span>{" "}
              {popupContent.subCounty}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <span style={{ color: "gray" }}>Ward: </span> {popupContent.ward}
            </Typography>
          </Card>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 4 }}>
          <Card
            sx={{
              borderRadius: "8px",
              p: 1,
              boxShadow: "0px 4px 16px #60606030",
              height: "100%",
            }}
          >
            <Typography>Revenue</Typography>
            <Divider sx={{ my: 1 }} />
            <Typography variant="body2" gutterBottom>
              <span style={{ color: "gray" }}>Expected: </span>
              {billingData?.totalBilledAmount ?? 0}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <span style={{ color: "gray" }}>Collected: </span>
              {billingData?.totalRevenue ?? 0}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <span style={{ color: "gray" }}>Arrears: </span>
              {billingData?.totalArrears ?? 0}
            </Typography>
          </Card>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 4 }}>
          <Card
            sx={{
              borderRadius: "8px",
              p: 1,
              boxShadow: "0px 4px 16px #60606030",
              height: "100%",
            }}
          >
            <Typography>Summary</Typography>
            <Divider sx={{ my: 1 }} />
            <Typography variant="body2" gutterBottom>
              <span style={{ color: "gray" }}>Land Rates: </span>
              {billingData?.totalLandRate ?? 0}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <span style={{ color: "gray" }}>Ground Rent: </span>
              {billingData?.totalLandRent ?? 0}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <span style={{ color: "gray" }}>Billed Amount: </span>
              {billingData?.totalBilledAmount ?? 0}
            </Typography>
          </Card>
        </Grid2>
      </Grid2>
      <Button
        variant="contained"
        onClick={() => {
          navigate(`/li/plots/${popupContent.id}`);
        }}
        sx={{ ml: "auto", display: "block", textTransform: "capitalize" }}
        size="small"
      >
        View Map
      </Button>
    </Paper>
  );
};
