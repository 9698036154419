import {
  getArea as getGeodeticArea,
  getLength as getGeodeticLength,
} from "ol/sphere";
import { useNavigate } from "react-router-dom";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Map from "ol/Map";
import { useState, useRef, useEffect } from "react";
import { OSM, XYZ } from "ol/source";
import {
  ZoomToExtent,
  defaults as defaultControls,
  ScaleLine,
  FullScreen,
  Attribution,
  Rotate,
  ZoomSlider,
} from "ol/control";
import { LineString, Circle as CircleGeom, Point, MultiPolygon } from "ol/geom";
import { Draw } from "ol/interaction";
import Overlay from "ol/Overlay";
import {
  Checkbox,
  Box,
  Typography,
  Card,
  Divider,
  Paper,
  ButtonGroup,
  Button,
  CircularProgress,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { fromLonLat, transform } from "ol/proj";
import {
  ArrowBack,
  CancelOutlined,
  RectangleOutlined,
  Timeline,
  TripOrigin,
} from "@mui/icons-material";
import FilterAndStyleBar from "./FilterAndStyleBar";
import { Layers, Satellite, Terrain } from "@mui/icons-material";
import proj4 from "proj4";
import { register } from "ol/proj/proj4.js";
import Projection from "ol/proj/Projection.js";
import { Feature } from "ol";
import {
  FilterAlt,
  Search as SearchIcon,
  MyLocation,
  ZoomIn,
  ZoomOut,
  Home,
  Layers as LayersIcon,
} from "@mui/icons-material";
import { TextField, IconButton } from "@mui/material";
proj4.defs(
  "EPSG:21036",
  "+proj=utm +zone=36 +south +datum=WGS84 +units=m +no_defs"
);
register(proj4);

export default function ParcelsMap(props) {
  const id = window.location.pathname.split("/")[3];
  const [map, setMap] = useState(null);
  const [drawInteraction, setDrawInteraction] = useState(null);
  const mapRef = useRef();
  mapRef.current = map;
  const popupRef = useRef();
  const [popupContent, setPopupContent] = useState(null);
  const [loadingLayers, setLoadingLayers] = useState([]);
  const measureSource = new VectorSource();
  const [featureSelectEnabled, setFeatureSelectEnabled] = useState(true);
  const [activeBasemap, setActiveBasemap] = useState("OSM");
  const navigate = useNavigate();
  const legendItems = [
    {
      label: "Subcounty Boundary",
      stroke: "#48CFCB",
      fill: "white",
      radius: 6,
      width: 2,
      table: "AdminUnits",
    },
    {
      label: "Ward Boundary",
      stroke: "#ED3EF7",
      fill: "white",
      radius: 4,
      width: 2,
      table: "AdminUnits",
    },
    {
      label: "Plots",
      stroke: "white",
      fill: "#0D7C66",
      radius: 8,
      width: 2,
      table: "MasterMeters",
    },
    {
      label: "Beacons",
      stroke: "white",
      fill: "orange",
      radius: 8,
      width: 2,
      table: "BulkMeters",
    },
  ];
  const [searchQuery, setSearchQuery] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [searchField, setSearchField] = useState("plotNo");
  const [showFilter, setShowFilter] = useState(false);
  const [selectedLayer, setSelectedLayer] = useState("");
  const [selectedColumn, setSelectedColumn] = useState("");
  const [selectedClassification, setSelectedClassification] =
    useState("Equal Interval");
  const [columns, setColumns] = useState([]);
  const [adminUnit, setAdminUnit] = useState(null);
  const [originalFeatures, setOriginalFeatures] = useState(null);

  const toggleBasemap = (label) => {
    setActiveBasemap(label);
    basemaps.forEach((basemap, index = 0) => {
      basemap.layer.setVisible(basemap.label === label);
    });
  };
  // Create the map instance
  useEffect(() => {
    const projection = new Projection({
      code: "EPSG:21036",
      units: "m",
      extent: [-20037508, -20048966, 20037508, 20048966],
      worldExtent: [-180, -85, 180, 85],
    });

    const initialCenter = transform([36.9, -0.5], "EPSG:4326", "EPSG:21036");

    const initialMap = new Map({
      target: "map",
      layers: basemaps.map((b) => b.layer),
      view: new View({
        zoom: 10,
        projection: projection,
        center: initialCenter,
      }),
      controls: defaultControls().extend([
        new ScaleLine(),
        new FullScreen(),
        new Attribution(),
        new Rotate(),
        new ZoomSlider(),
        new ZoomToExtent({
          extent: [
            633401.3446711299475282, 9919002.521270414814353,
            693716.2872443984961137, 9961211.8638889770954847,
          ],
        }),
      ]),
    });
    setMap(initialMap);
    return () => {
      initialMap.setTarget(null);
    };
  }, []);

  const fetchAndDisplayLayer = async (endpoint, layerTitle, style, index) => {
    try {
      // Check if layer already exists
      const existingLayer = map
        .getLayers()
        .getArray()
        .find((layer) => layer.get("title") === layerTitle);

      if (existingLayer) {
        console.log(`Layer ${layerTitle} already exists`);
        return;
      }

      setLoadingLayers((prev) => [...prev, layerTitle]);
      const response = await fetch(endpoint);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (Array.isArray(data)) {
        const features = data
          .map((item) => {
            if (item.geom && item.geom.coordinates) {
              const feature = new Feature({
                geometry: new MultiPolygon(item.geom.coordinates),
              });

              // Set properties
              Object.entries(item).forEach(([key, value]) => {
                if (typeof value !== "object") {
                  feature?.set(key, value);
                }
              });

              // Set style
              feature?.setStyle(style);

              return feature;
            }
          })
          .filter(Boolean);

        const vectorSource = new VectorSource({
          features: features,
        });

        const vectorLayer = new VectorLayer({
          source: vectorSource,
          title: layerTitle,
        });

        // Insert the layer at the specified index
        map.getLayers().insertAt(index, vectorLayer);

        // Fit view to the extent if it's the first layer
        if (layerTitle === "Plots") {
          const extent = vectorSource.getExtent();
          map.getView().fit(extent, {
            padding: [20, 20, 20, 20],
            duration: 1000,
          });
        }
      }
    } catch (error) {
      console.error(`Error fetching ${layerTitle}:`, error);
    } finally {
      setLoadingLayers((prev) => prev.filter((layer) => layer !== layerTitle));
    }
  };

  const fetchAndDisplayPolygons = async () => {
    try {
      // Land Parcels
      const response = await fetch(
        `/api/landparcels/adminUnit/${id}?includeAdminUnits=true`
      );
      const data = await response.json();

      if (Array.isArray(data)) {
        const features = data
          .map((item) => {
            if (item.geom && item.geom.coordinates) {
              const feature = new Feature({
                geometry: new MultiPolygon(item.geom.coordinates),
              });

              // Set basic properties
              Object.entries(item).forEach(([key, value]) => {
                if (typeof value !== "object") {
                  feature?.set(key, value);
                }
              });

              // Set AdminUnit properties explicitly
              if (item.AdminUnit) {
                feature?.set("subCounty", item.AdminUnit.subCounty);
                feature?.set("ward", item.AdminUnit.ward);
                feature?.set("town", item.AdminUnit.name);
                feature?.set("type", item.AdminUnit.type);
                feature?.set("location", item.AdminUnit.location);
              }

              return feature;
            }
          })
          .filter(Boolean);

        setOriginalFeatures(features); // Store original features

        const vectorSource = new VectorSource({
          features: features,
        });

        const vectorLayer = new VectorLayer({
          source: vectorSource,
          title: "Plots",
          style: new Style({
            fill: new Fill({ color: "rgba(13, 124, 102, 0.4)" }),
            stroke: new Stroke({ color: "#0D7C66", width: 1 }),
          }),
        });

        map.addLayer(vectorLayer);

        // Fit view to the extent
        const extent = vectorSource.getExtent();
        map.getView().fit(extent, {
          padding: [20, 20, 20, 20],
          duration: 1000,
        });
      }
    } catch (error) {
      console.error("Error fetching plots:", error);
    }

    // Subcounties
    await fetchAndDisplayLayer(
      `/api/landparcels/boundaries?subcounty=true`,
      "Subcounty Boundary",
      new Style({
        // fill: new Fill({ color: "rgba(72, 207, 203, 0.2)" }),
        stroke: new Stroke({ color: "#48CFCB", width: 2 }),
      }),
      1 // Insert at index 0
    );

    // Wards
    await fetchAndDisplayLayer(
      `/api/landparcels/boundaries?ward=true`,
      "Ward Boundary",
      new Style({
        // fill: new Fill({ color: "rgba(237, 62, 247, 0.2)" }),
        stroke: new Stroke({ color: "#ED3EF7", width: 1 }),
      }),
      2 // Insert at index 1
    );
  };

  useEffect(() => {
    if (map) {
      fetchAndDisplayPolygons();
    }
  }, [map, id]);

  useEffect(() => {
    if (map) {
      const popupOverlay = new Overlay({
        element: popupRef.current,
        positioning: "bottom-center",
        stopEvent: false,
      });
      map.addOverlay(popupOverlay);
      map.on("singleclick", function (event) {
        if (featureSelectEnabled) {
          const features = map.getFeaturesAtPixel(event.pixel);

          if (features.length > 0) {
            const properties = features[0].getProperties();
            console.log(properties);

            delete properties.geometry;
            delete properties.ID;
            delete properties.createdAt;
            delete properties.updatedAt;
            delete properties.User;
            delete properties.Coordinates;
            delete properties.Picture;

            if (properties?.RecTime != undefined) {
              properties.RecTime = properties.RecTime.split(" ")[0];
            }
            if (properties?.FRecTime != undefined) {
              properties.FRecTime = properties.FRecTime.split(" ")[0];
            }

            setPopupContent(properties);
            popupOverlay.setPosition(event.coordinate);
          } else {
            setPopupContent(null);
            popupOverlay.setPosition(undefined);
          }
        }
      });
    }
  }, [map, featureSelectEnabled]);

  const addDrawInteraction = (type) => {
    if (drawInteraction) {
      map.removeInteraction(drawInteraction);
    }
    setFeatureSelectEnabled(false);

    const draw = new Draw({
      source: measureSource,
      type: type === "circle" ? "Circle" : type,
    });

    let sketch;
    let measureTooltipElement;
    let measureTooltip;

    const formatLengthOrArea = (geom) => {
      let output;

      if (geom instanceof LineString) {
        // Get length directly from the geometry in UTM (meters)
        const length = geom.getLength();
        output = `D: ${length.toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 0,
        })} m`;
      } else if (geom instanceof MultiPolygon) {
        // Get area directly from the geometry in UTM (square meters)
        const area = geom.getArea();
        output = `A: ${area.toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 0,
        })} sq m`;
      } else if (geom instanceof CircleGeom) {
        // Calculate radius and area for a Circle
        const radius = geom.getRadius(); // Radius is in meters in UTM
        const area = Math.PI * Math.pow(radius, 2); // Area in square meters
        output = `R: ${radius.toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 0,
        })} m, <br /> A: ${area.toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 0,
        })} sq m`;
      }

      return output;
    };

    const createMeasureTooltip = () => {
      if (measureTooltipElement) {
        measureTooltipElement.parentNode.removeChild(measureTooltipElement);
      }
      measureTooltipElement = document.createElement("div");
      measureTooltipElement.className = "ol-tooltip ol-tooltip-measure";
      measureTooltip = new Overlay({
        element: measureTooltipElement,
        offset: [0, -15],
        positioning: "bottom-center",
      });
      map.addOverlay(measureTooltip);
    };

    draw.on("drawstart", (evt) => {
      sketch = evt.feature;

      let tooltipCoord = evt.coordinate;

      sketch.getGeometry().on("change", (event) => {
        const geom = event.target;
        const output = formatLengthOrArea(geom);
        tooltipCoord = geom.getLastCoordinate();

        measureTooltipElement.innerHTML = output;
        measureTooltip.setPosition(tooltipCoord);
      });
    });

    draw.on("drawend", (event) => {
      const geom = event.feature?.getGeometry();
      const output = formatLengthOrArea(geom);
      const tooltipCoord = getCenterCoordinate(geom);

      measureTooltipElement.innerHTML = output;
      measureTooltip.setPosition(tooltipCoord);

      measureTooltipElement.className = "ol-tooltip ol-tooltip-static";
      measureTooltip.setOffset([0, 5]);

      sketch = null;
      measureTooltipElement = null;
      createMeasureTooltip();

      // Ensure the sketch is added to the map layer (measureSource)
      const vectorLayer = new VectorLayer({
        title: "measure",
        source: measureSource,
        style: new Style({
          stroke: new Stroke({
            color: "#9acd32",
            width: 2,
          }),
          fill: new Fill({
            color: "rgba(255, 255, 255, 0.2)",
          }),
          image: new CircleStyle({
            radius: 7,
            stroke: new Stroke({
              color: "#9acd32",
              width: 2,
            }),
            fill: new Fill({
              color: "rgba(255, 255, 255, 0.2)",
            }),
          }),
        }),
      });
      map.addLayer(vectorLayer);
    });

    createMeasureTooltip();
    map.addInteraction(draw);
    setDrawInteraction(draw);
  };

  const clearMeasurements = () => {
    setFeatureSelectEnabled(true); // Re-enable feature selection after drawing
    measureSource.clear(); // Clear the vector source
    map.getLayers().forEach((layer) => {
      if (layer instanceof VectorLayer && layer.get("title") === "measure") {
        map.removeLayer(layer); // Remove the measurement layer
      }
    });
    if (drawInteraction) {
      map.removeInteraction(drawInteraction);
    }
    map.getOverlays().clear(); // Remove any measurement overlays
  };

  const getCenterCoordinate = (geom) => {
    if (geom instanceof LineString) {
      // Calculate the midpoint of the LineString
      const coordinates = geom.getCoordinates();
      const midIndex = Math.floor(coordinates.length / 2);
      return coordinates[midIndex];
    } else if (geom instanceof MultiPolygon) {
      // Calculate the centroid of the Polygon
      const extent = geom.getExtent();
      return [
        (extent[0] + extent[2]) / 2, // (minX + maxX) / 2
        (extent[1] + extent[3]) / 2, // (minY + maxY) / 2
      ];
    } else if (geom instanceof CircleGeom) {
      // Get the center of the Circle
      return geom.getCenter();
    }
    return null;
  };

  const zoomToUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const coords = transform(
          [position.coords.longitude, position.coords.latitude],
          "EPSG:4326",
          "EPSG:21036"
        );
        map.getView().animate({
          center: coords,
          zoom: 15,
          duration: 1000,
        });
      });
    }
  };

  const zoomIn = () => {
    const view = map.getView();
    view.animate({
      zoom: view.getZoom() + 1,
      duration: 250,
    });
  };

  const zoomOut = () => {
    const view = map.getView();
    view.animate({
      zoom: view.getZoom() - 1,
      duration: 250,
    });
  };

  const zoomToHome = () => {
    const initialCenter = transform(
      [34.45684, -0.53283],
      "EPSG:4326",
      "EPSG:21036"
    );
    map.getView().animate({
      center: initialCenter,
      zoom: 10,
      duration: 1000,
    });
  };

  const handleSearch = async () => {
    if (!searchQuery) return;

    try {
      const response = await fetch(
        `/api/landparcels?${searchField}=${searchQuery}&adminUnitId=${id}&includeAdminUnits=true`
      );
      const data = await response.json();

      if (response.ok) {
        const features = data.data
          .map((item) => {
            if (item.geom && item.geom.coordinates) {
              const feature = new Feature({
                geometry: new MultiPolygon(item.geom.coordinates),
              });

              // Set basic properties
              Object.entries(item).forEach(([key, value]) => {
                if (typeof value !== "object") {
                  feature?.set(key, value);
                }
              });

              // Set AdminUnit properties explicitly
              if (item.AdminUnit) {
                feature?.set("subCounty", item.AdminUnit.subCounty);
                feature?.set("ward", item.AdminUnit.ward);
                feature?.set("town", item.AdminUnit.name);
                feature?.set("type", item.AdminUnit.type);
                feature?.set("location", item.AdminUnit.location);
              }

              return feature;
            }
          })
          .filter(Boolean);

        // Get the land parcels layer
        const landParcelsLayer = map
          .getLayers()
          .getArray()
          .find((layer) => layer.get("title") === "Plots");

        if (landParcelsLayer) {
          // Update the source with new features
          const source = landParcelsLayer.getSource();
          source.clear();
          source.addFeatures(features);

          // Update style to highlight search results
          landParcelsLayer.setStyle(
            new Style({
              fill: new Fill({ color: "rgba(255, 255, 0, 0.4)" }),
              stroke: new Stroke({ color: "#FFD700", width: 2 }),
            })
          );

          // Zoom to search results
          if (features.length > 0) {
            const extent = source.getExtent();
            map.getView().fit(extent, {
              padding: [50, 50, 50, 50],
              duration: 1000,
            });
          }
        }
      }
    } catch (error) {
      console.error("Search error:", error);
    }
  };

  const resetSearch = () => {
    setSearchQuery("");

    if (originalFeatures) {
      const landParcelsLayer = map
        .getLayers()
        .getArray()
        .find((layer) => layer.get("title") === "Plots");

      if (landParcelsLayer) {
        // Reset to original features
        const source = landParcelsLayer.getSource();
        source.clear();
        source.addFeatures(originalFeatures);

        // Reset to original style
        landParcelsLayer.setStyle(
          new Style({
            fill: new Fill({ color: "rgba(13, 124, 102, 0.4)" }),
            stroke: new Stroke({ color: "#0D7C66", width: 1 }),
          })
        );

        // Zoom to full extent
        const extent = source.getExtent();
        map.getView().fit(extent, {
          padding: [20, 20, 20, 20],
          duration: 1000,
        });
        setOriginalFeatures(null);
        setSearchQuery("");
        setShowSearch(false);
      }
    }
  };

  useEffect(() => {
    if (selectedLayer && map) {
      // Get the layer's features
      const layer = map
        .getLayers()
        .getArray()
        .find((l) => l.get("title") === selectedLayer);
      if (layer) {
        const features = layer.getSource().getFeatures();
        if (features.length > 0) {
          // Get all properties except geometry
          const properties = features[0].getProperties();
          const cols = Object.keys(properties).filter(
            (key) => key !== "geometry" && typeof properties[key] !== "object"
          );
          setColumns(cols);
        }
      }
    }
  }, [selectedLayer, map]);

  const fetchAdminUnit = async () => {
    try {
      const response = await fetch(`/api/adminunits/${id}`);
      if (response.ok) {
        const data = await response.json();
        setAdminUnit(data);
      }
    } catch (error) {
      console.error("Error fetching admin unit:", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchAdminUnit();
    }
  }, [id]);

  return (
    <Card
      sx={{
        boxShadow: "0px 4px 16px #60606040",

        p: 2,
        borderRadius: "8px",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}>
        {adminUnit ? (
          <Box flex={1}>
            <Typography variant="h6" gutterBottom>
              {adminUnit.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {adminUnit.ward} Ward, {adminUnit.subCounty} Sub-County
            </Typography>
          </Box>
        ) : (
          <Typography variant="title" flex={1}>
            Loading...
          </Typography>
        )}
        <Button size="small" onClick={() => navigate(-1)}>
          <ArrowBack fontSize="small" />
        </Button>
      </Box>
      <Box sx={{ position: "relative" }}>
        <div id="map" style={{ width: "100%", height: "100vh" }} />

        <Card
          sx={{
            position: "absolute",
            top: "0.5em",
            left: "2.5rem",
            zIndex: 120,
            display: "flex",
            alignItems: "center",
            backgroundColor: showSearch ? "rgba(0,0,0,0.1)" : "white",
            p: 1,
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "rgba(0,0,0,0.1)",
            },
          }}
          onClick={() => {
            setShowSearch(!showSearch);
            setShowFilter(false); // Close filter when opening search
          }}
        >
          <SearchIcon />
        </Card>

        {showSearch && (
          <Box
            sx={{
              position: "absolute",
              top: "0.5rem",
              left: "8.5rem",
              zIndex: 1000,
              backgroundColor: "white",
              padding: "12px",
              borderRadius: "4px",
              boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              minWidth: 300,
            }}
          >
            <FormControl size="small">
              <InputLabel>Search By</InputLabel>
              <Select
                value={searchField}
                onChange={(e) => setSearchField(e.target.value)}
              >
                <MenuItem value="plotNo">Plot Number</MenuItem>
                <MenuItem value="lrNo">LR Number</MenuItem>
                <MenuItem value="frNo">FR Number</MenuItem>
                <MenuItem value="area">Area</MenuItem>
              </Select>
            </FormControl>

            <TextField
              size="small"
              label={`Enter ${searchField}`}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
              fullWidth
            />

            <Box sx={{ display: "flex", gap: 1 }}>
              <Button
                variant="contained"
                onClick={handleSearch}
                disabled={!searchQuery}
                fullWidth
              >
                Search
              </Button>
              <Button
                variant="outlined"
                onClick={resetSearch}
                disabled={!searchQuery}
                fullWidth
              >
                Reset
              </Button>
            </Box>

            {/* Optional: Show search results count */}
            <Box sx={{ mt: 1 }}>
              <Typography variant="caption" color="text.secondary">
                Press Enter to search or click the Search button
              </Typography>
            </Box>
          </Box>
        )}
        <Card
          sx={{
            position: "absolute",
            top: "0.5em",
            left: "5.5em",
            zIndex: 120,
            display: "flex",
            alignItems: "center",
            backgroundColor: showFilter ? "rgba(0,0,0,0.1)" : "white",
            p: 1,
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "rgba(0,0,0,0.1)",
            },
          }}
          onClick={() => {
            setShowFilter(!showFilter);
            if (!showFilter) {
              setSelectedLayer(null);
              setSelectedColumn(null);
              setSelectedClassification(null);
            }
            setShowSearch(false); // Close search when opening filter
          }}
        >
          <FilterAlt />
        </Card>

        {showFilter && (
          <FilterAndStyleBar
            legendItems={legendItems}
            map={map}
            selectedLayer={selectedLayer}
            setSelectedLayer={setSelectedLayer}
            selectedColumn={selectedColumn}
            setSelectedColumn={setSelectedColumn}
            selectedClassification={selectedClassification}
            setSelectedClassification={setSelectedClassification}
          />
        )}
        <Box
          sx={{
            position: "absolute",
            bottom: "2.3em",
            left: "0.5em",
            zIndex: 120,
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <ButtonGroup orientation="vertical">
            {basemaps.map((basemap, index) => (
              <Button
                size="small"
                key={index}
                onClick={() => toggleBasemap(basemap.label)}
                variant={
                  activeBasemap === basemap.label ? "contained" : "outlined"
                }
              >
                {basemap.icon}
              </Button>
            ))}
          </ButtonGroup>
        </Box>

        <Card
          sx={{
            position: "absolute",
            bottom: "1.5rem",
            backgroundColor: "rgba(255,255,255,0.7)",
            right: "0.5em",
            py: 1,
            px: 2,
            borderRadius: "8px",
          }}
        >
          <Typography variant="h6">Legend</Typography>
          <Divider sx={{ mb: "10px" }} />
          {legendItems.map((item, i) => (
            <LegendItem key={i} item={item} map={map} />
          ))}
        </Card>
        <Box
          sx={{
            position: "absolute",
            top: "40%",
            right: "0.5em",
            zIndex: 11,
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            backgroundColor: "white",
            borderRadius: "4px",
            boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
          }}
        >
          <ButtonGroup orientation="vertical">
            <Button
              size="small"
              onClick={() => addDrawInteraction("LineString")}
            >
              <Timeline fontSize="small" />
            </Button>
            <Button size="small" onClick={() => addDrawInteraction("Polygon")}>
              <RectangleOutlined fontSize="small" />
            </Button>
            <Button size="small" onClick={() => addDrawInteraction("circle")}>
              <TripOrigin fontSize="small" />
            </Button>
            <Button size="small" onClick={clearMeasurements}>
              <CancelOutlined fontSize="small" />
            </Button>
          </ButtonGroup>
        </Box>

        <Box
          sx={{
            position: "absolute",
            top: "2.3rem",
            right: "0.5em",
            zIndex: 11,
            display: "flex",
            flexDirection: "column",
            gap: "2px",
            backgroundColor: "white",
            borderRadius: "4px",
            boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
          }}
        >
          <IconButton onClick={zoomIn}>
            <ZoomIn />
          </IconButton>
          <IconButton onClick={zoomOut}>
            <ZoomOut />
          </IconButton>
          <IconButton onClick={zoomToUserLocation}>
            <MyLocation />
          </IconButton>
          <IconButton onClick={zoomToHome}>
            <Home />
          </IconButton>
        </Box>

        <Box
          sx={{
            position: "absolute",
            top: "2.5rem",
            right: "1.5em",
            zIndex: 11,
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          {loadingLayers.map((label, index) => (
            <LoadingBar key={index} label={label} />
          ))}
        </Box>

        <Paper
          ref={popupRef}
          sx={{
            position: "absolute",
            backgroundColor: "white",
            padding: "10px",
            borderRadius: "8px",
            display: popupContent ? "block" : "none",
            zIndex: 10,
            transform: "translate(-50%, -100%)",
          }}
        >
          {popupContent && (
            <Popup popupContent={popupContent} popupRef={popupRef} />
          )}
        </Paper>
      </Box>
    </Card>
  );
}

const basemaps = [
  {
    label: "OSM",
    layer: new TileLayer({
      source: new OSM(),
      title: "OSM",
      visible: true,
    }),
    icon: <Layers fontSize="small" />,
  },
  {
    label: "Satellite",
    layer: new TileLayer({
      source: new XYZ({
        url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/mapServer/tile/{z}/{y}/{x}", // Replace this with a proper Satellite tile URL
      }),
      title: "Satellite",
      visible: false,
    }),
    icon: <Satellite fontSize="small" />,
  },
  {
    label: "Terrain",
    layer: new TileLayer({
      source: new XYZ({
        url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/mapServer/tile/{z}/{y}/{x}", // OpenTopoMap
      }),
      title: "Terrain",
      visible: false,
    }),
    icon: <Terrain fontSize="small" />,
  },
];

const LegendItem = (props) => {
  const [showing, setShowing] = useState(true);

  useEffect(() => {
    if (props.map) {
      props.map.getLayers().forEach((layer) => {
        if (layer && layer.get("title") === props.item.label) {
          layer.setVisible(showing);
        }
      });
    }
  }, [showing, props.map, props.item.label]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        mb: "3px",
        gap: 1,
      }}
    >
      <Checkbox
        checked={showing}
        sx={{
          height: 10,
          width: 10,
        }}
        onChange={(e) => setShowing(e.target.checked)}
      />
      <Box
        sx={{
          border: `1px solid ${props.item.stroke}`,
          backgroundColor: props.item.fill,
          height: 16,
          width: 16,
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      />
      <Typography variant="body2">{props.item.label}</Typography>
    </Box>
  );
};

const LoadingBar = ({ label }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        padding: "8px",
        borderRadius: "8px",
        mb: 1,
      }}
    >
      <CircularProgress size={20} sx={{ mr: 1 }} />
      <Typography variant="body2">{label} is loading...</Typography>
    </Box>
  );
};

const Popup = ({ popupRef, popupContent }) => {
  const [owner, setOwner] = useState(null);
  const [valuation, setValuation] = useState(null);
  const [billing, setBilling] = useState(null);

  return (
    <Paper
      ref={popupRef}
      sx={{
        position: "absolute",
        backgroundColor: "white",
        padding: "10px",
        borderRadius: "8px",
        display: popupContent ? "block" : "none",
        zIndex: 10,
        transform: "translate(-50%, -100%)",
        minWidth: { xs: "85vw", md: "30vw" },
      }}
    >
      <Typography variant="subtitle1">Feature Details</Typography>
      <Divider sx={{ mb: "5px" }} />
      <Card
        sx={{
          borderRadius: "8px",
          p: 2,
          boxShadow: "0px 4px 16px #60606030",
        }}
      >
        <Typography>Land Parcel Details</Typography>
        <Divider sx={{ my: 1 }} />
        <Typography variant="body2">
          <strong>Area:</strong> {popupContent.area}
        </Typography>
        <Typography variant="body2">
          <strong>Plot No:</strong> {popupContent.plotNo}
        </Typography>
        <Typography variant="body2">
          <strong>LR No:</strong> {popupContent.lrNo || "N/A"}
        </Typography>
        <Typography variant="body2">
          <strong>FR No:</strong> {popupContent.frNo || "N/A"}
        </Typography>
        <Typography variant="body2">
          <strong>Created At:</strong>{" "}
          {new Date(popupContent.createdAt).toLocaleString()}
        </Typography>
        <Typography variant="body2">
          <strong>Updated At:</strong>{" "}
          {new Date(popupContent.updatedAt).toLocaleString()}
        </Typography>
      </Card>
    </Paper>
  );
};
