import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Chip,
  Alert,
  Divider,
  Stack,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Grid2,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const DataValuations = () => {
  const [data, setData] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [creating, setCreating] = useState(false);
  const [editing, setEditing] = useState(false);
  const [selectedValuationId, setSelectedValuationId] = useState(null);
  const [adminUnits, setAdminUnits] = useState(null);
  const [selectedAdminUnitId, setSelectedAdminUnitId] = useState(""); // New state for selected admin unit

  const [valuationData, setValuationData] = useState({
    plotNo: "",
    adminUnitId: "",
    srNo: "",
    lrNo: "",
    oldLrNo: "",
    vbNo: "",
    location: "",
    size: "",
    cgh: "",
    private: "",
    tenure: "",
    use: "",
    remarks: "",
  });
  //data fetch
  const [column, setColumn] = useState("name");
  const [searchValue, setSearchValue] = useState("");
  const [changed, setChanged] = useState(false);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  useEffect(() => {
    fetchAdminUnits(); // Fetch admin units
  }, []);

  useEffect(() => {
    if (searchValue == "") {
      fetchValuationData();
    }
  }, [changed, paginationModel, searchValue]);

  // Fetch admin units from backend
  const fetchAdminUnits = async () => {
    try {
      const response = await fetch("/api/adminunits");
      const data = await response.json();
      setAdminUnits(data);
    } catch (error) {
      console.error("Error fetching admin units:", error);
    }
  };

  const fetchValuationData = async () => {
    try {
      setData(null);
      setLoading(true);
      const response = await fetch(
        `/api/valuations?offset=${
          offset * limit
        }&limit=${limit}&includeAdminUnits=true`
      );
      const data = await response.json();
      if (response.ok) {
        const transformedData = data.data.map((item) => ({
          ...item,
          AdminUnit: item.AdminUnit,
          subCounty: item?.AdminUnit?.subCounty,
          ward: item?.AdminUnit?.ward,
          town: item?.AdminUnit?.name,
        }));
        setData({ ...data, data: transformedData });
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleAdminUnitChange = (event) => {
    setSelectedAdminUnitId(event.target.value);
    setValuationData((prev) => ({ ...prev, adminUnitId: event.target.value }));
  };

  const handleSearch = async (value) => {
    if (value != "") {
      setSearchValue(value);
      setData(null);
      setOffset(0);
      setLoading(true);
      try {
        const response = await fetch(
          `/api/valuations?offset=${
            offset * limit
          }&limit=${limit}&${column}=${value}&includeAdminUnits=true`
        );
        const data = await response.json();
        if (response.ok) {
          setData(data);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    } else {
      setSearchValue("");
      setChanged(!changed);
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setEditing(false);
    setValuationData({
      plotNo: "",
      adminUnitId: "",
      srNo: "",
      lrNo: "",
      oldLrNo: "",
      vbNo: "",
      location: "",
      size: "",
      cgh: "",
      private: "",
      tenure: "",
      use: "",
      remarks: "",
    });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedAdminUnitId("");
    setError("");
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setValuationData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSaveValuation = async () => {
    if (
      !valuationData.plotNo ||
      !valuationData.adminUnitId ||
      !valuationData.lrNo ||
      !valuationData.size ||
      !valuationData.tenure ||
      !valuationData.use
    ) {
      setError("Please fill in all required fields.");
      return;
    }

    setCreating(true);
    try {
      const method = editing ? "PUT" : "POST";
      const response = await fetch(
        `/api/valuations${editing ? `/${selectedValuationId}` : ""}`,
        {
          method,
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(valuationData),
        }
      );
      const body = await response.json();
      if (response.ok) {
        setError(body?.message);
        setTimeout(() => {
          fetchValuationData();
          handleCloseDialog();
        }, 1000);
      } else {
        setError(body?.message);
      }
    } catch (error) {
      setError("Error saving valuation. Please try again.");
    } finally {
      setCreating(false);
    }
  };

  const handleEditValuation = (valuation) => {
    setValuationData(valuation);
    setSelectedValuationId(valuation.id);
    setEditing(true);
    setOpenDialog(true);
  };

  const handleDeleteValuation = async (id) => {
    if (window.confirm("Are you sure you want to delete this valuation?")) {
      try {
        const response = await fetch(`/api/valuations/${id}`, {
          method: "DELETE",
        });
        const body = await response.json();
        if (response.ok) {
          setError(body?.message);
          fetchValuationData();
        } else {
          setError(body?.message);
        }
      } catch (error) {
        setError("Error deleting valuation. Please try again.");
      }
    }
  };

  const columns = [
    {
      field: "index",
      headerName: "No",
      width: 70,
      renderCell: (params) => {
        const index = data.data.findIndex((e) => e.id === params?.row?.id);
        return (
          <Chip
            label={index + 1 + paginationModel.page * paginationModel.pageSize}
          />
        );
      },
    },
    { field: "srNo", headerName: "SN" },
    { field: "plotNo", headerName: "Plot No", flex: 0.5 },
    { field: "lrNo", headerName: "LR No", flex: 0.5 },
    { field: "oldLrNo", headerName: "Old LR No", flex: 0.5 },
    {
      field: "subCounty",
      headerName: "Subcounty",
      flex: 1,
    },
    {
      field: "ward",
      headerName: "Ward",
      flex: 1,
    },
    {
      field: "town",
      headerName: "Town",
      flex: 1,
    },
    { field: "location", headerName: "Location", flex: 1 },
    { field: "size", headerName: "Size", flex: 0.5 },
    { field: "cgh", headerName: "CGH", flex: 0.5 },
    { field: "private", headerName: "Private", flex: 0.5 },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleEditValuation(params.row)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDeleteValuation(params.row.id)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <Box>
      <Card sx={{ boxShadow: "0px 8px 32px #60606040", p: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2, gap: 2 }}>
          <Box flexGrow={1}></Box>
          <FormControl>
            <InputLabel size="small">Search by...</InputLabel>
            <Select
              label="Search by..."
              size="small"
              onChange={(e) => setColumn(e.target.value)}
              value={column}
            >
              <MenuItem value="name">Name</MenuItem>
              <MenuItem value="subCounty">Sub County</MenuItem>
              <MenuItem value="ward">Ward</MenuItem>
            </Select>
          </FormControl>
          <TextField
            variant="outlined"
            size="small"
            label="Search..."
            value={searchValue}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleOpenDialog}
            sx={{ color: "white", textTransform: "capitalize" }}
          >
            Create New
          </Button>
        </Box>

        <div style={{  width: "100%" }}>
          <DataGrid
            rows={data ? data.data : []}
            columns={columns}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  srNo: false,
                  lrNo: false,
                  oldLrNo: false,
                  location: false,
                },
              },
            }}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            rowCount={data?.total ?? 0}
            paginationMode="server"
            onPageChange={(params) => setOffset(params.page)}
            onPageSizeChange={(params) => setLimit(params.pageSize)}
            loading={loading}
            slots={{
              toolbar: GridToolbar,
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
          />
        </div>
      </Card>

      <Dialog fullWidth open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {editing ? "Edit Record" : "New Valuation Record"}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Stack spacing={2}>
            <FormControl fullWidth>
              <InputLabel>Admin Unit</InputLabel>
              <Select
                value={selectedAdminUnitId ?? valuationData.adminUnitId}
                onChange={handleAdminUnitChange}
                label="Admin Unit"
              >
                {adminUnits?.data.map((unit) => (
                  <MenuItem key={unit.id} value={unit.id}>
                    {unit.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Grid2 container spacing={2}>
              <Grid2 size={{ xs: 12, md: 4 }}>
                <TextField
                  fullWidth
                  label="Plot No"
                  name="plotNo"
                  value={valuationData.plotNo}
                  onChange={handleInputChange}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 4 }}>
                <TextField
                  fullWidth
                  label="LR Number"
                  name="lrNo"
                  value={valuationData.lrNo}
                  onChange={handleInputChange}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 4 }}>
                <TextField
                  fullWidth
                  label="Old LR Number"
                  name="oldLrNo"
                  value={valuationData.oldLrNo}
                  onChange={handleInputChange}
                />
              </Grid2>
            </Grid2>
            <TextField
              fullWidth
              label="Location"
              name="location"
              value={valuationData.location}
              onChange={handleInputChange}
            />
            <Grid2 container spacing={2}>
              <Grid2 size={{ xs: 12, md: 4 }}>
                <TextField
                  fullWidth
                  label="Size"
                  name="size"
                  type="number"
                  value={valuationData.size}
                  onChange={handleInputChange}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 4 }}>
                <TextField
                  fullWidth
                  label="CGH"
                  name="cgh"
                  type="number"
                  value={valuationData.cgh}
                  onChange={handleInputChange}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 4 }}>
                <TextField
                  fullWidth
                  label="Private"
                  name="private"
                  type="number"
                  value={valuationData.private}
                  onChange={handleInputChange}
                />
              </Grid2>
            </Grid2>
            <Grid2 container spacing={2}>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <FormControl fullWidth>
                  <InputLabel>Tenure</InputLabel>
                  <Select
                    name="tenure"
                    value={valuationData.tenure}
                    onChange={handleInputChange}
                    label="Tenure"
                  >
                    <MenuItem value="Freehold">Freehold</MenuItem>
                    <MenuItem value="Leasehold">Leasehold</MenuItem>
                  </Select>
                </FormControl>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <FormControl fullWidth>
                  <InputLabel>Land Use</InputLabel>
                  <Select
                    name="use"
                    value={valuationData.use}
                    onChange={handleInputChange}
                    label="Land Use"
                  >
                    <MenuItem value="Commercial">Commercial</MenuItem>
                    <MenuItem value="Industrial">Industrial</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid2>
            </Grid2>
            <TextField
              fullWidth
              multiline
              rows={3}
              label="Remarks"
              name="remarks"
              value={valuationData.remarks}
              onChange={handleInputChange}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            color="primary"
            startIcon={<CancelIcon />}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSaveValuation}
            color="primary"
            startIcon={creating ? <SaveIcon /> : <SaveIcon />}
            disabled={creating}
          >
            {creating ? "Saving..." : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DataValuations;
