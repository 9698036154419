import React, { useEffect, useRef, useState } from "react";
import "ol/ol.css";
import { Map, View } from "ol";
import { Tile as TileLayer } from "ol/layer";
import { OSM } from "ol/source";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import { Feature } from "ol";
import { MultiPolygon } from "ol/geom";
import { fromLonLat } from "ol/proj";
import { Fill, Stroke, Style, Text } from "ol/style";
import { defaults as defaultInteractions } from "ol/interaction";
import { defaults as defaultControls } from "ol/control";
import proj4 from "proj4";
import { register } from "ol/proj/proj4.js";
import Projection from "ol/proj/Projection.js";

// Define the UTM projection for Zone 36S
proj4.defs(
  "EPSG:21036",
  "+proj=utm +zone=36 +south +datum=WGS84 +units=m +no_defs"
);
register(proj4);

export default function SingleParcel({ geom, area }) {
  const [map, setMap] = useState(null);
  const mapRef = useRef();

  useEffect(() => {
    if (geom && geom.coordinates) {
      const polygonFeature = new Feature({
        type: "MultiPolygon",
        geometry: new MultiPolygon(geom.coordinates),
      });

      // Set polygon style with label
      polygonFeature.setStyle(
        new Style({
          fill: new Fill({ color: "rgba(0, 128, 0, 0.4)" }),
          stroke: new Stroke({ color: "#028e37", width: 1 }),
          text: new Text({
            text: area + " ac" || "", // Display regArea as label
            font: "14px Calibri,sans-serif",
            fill: new Fill({ color: "#0F67B9" }),
            overflow: true,
          }),
        })
      );

      const vectorSource = new VectorSource({
        features: [polygonFeature],
      });

      const vectorLayer = new VectorLayer({
        source: vectorSource,
      });
      const extent = polygonFeature.getGeometry().getExtent();
      const projection = new Projection({
        code: "EPSG:21036",
        units: "m",
        extent: [-20037508, -20048966, 20037508, 20048966],
      });

      // Initialize map instance
      const initialMap = new Map({
        target: mapRef.current,
        layers: [
          new TileLayer({
            source: new OSM(),
          }),
          vectorLayer,
        ],
        view: new View({
          projection: projection,
          // extent: extent,
        }),
        interactions: defaultInteractions({ mouseWheelZoom: false }),
        controls: defaultControls({ zoom: false, attribution: false }),
      });

      setMap(initialMap);

      initialMap.getView().fit(extent, {
        padding: [10, 10, 10, 10],
        duration: 1000,
      });
      return () => initialMap.setTarget(null);
    }
  }, [geom, area]);

  return (
    <div
      ref={mapRef}
      style={{
        width: "150px",
        height: "100px",
        borderRadius: "8px",
        boxShadow: "0px 4px 16px #60606040",
        overflow: "hidden",
      }}
    />
  );
}
