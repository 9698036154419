import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
  Button,
  Divider,
  Chip,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const DataValuations = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [column, setColumn] = useState("plotNo");
  const [searchValue, setSearchValue] = useState("");
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [sortModel, setSortModel] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedValuation, setSelectedValuation] = useState(null);

  useEffect(() => {
    fetchValuationData();
  }, [paginationModel, sortModel, searchValue]);

  const fetchValuationData = async () => {
    setLoading(true);
    try {
      const searchParam = searchValue ? `&${column}=${searchValue}` : "";
      const sortParam =
        sortModel.length > 0
          ? `&sortBy=${sortModel[0].field}&sortOrder=${sortModel[0].sort}`
          : "";
      const response = await fetch(
        `/api/valuations?offset=${
          paginationModel.page * paginationModel.pageSize
        }&limit=${
          paginationModel.pageSize
        }${searchParam}${sortParam}&includeAdminUnits=true`
      );
      const result = await response.json();
      if (response.ok) {
        const transformedData = result.data.map((item) => ({
          ...item,
          AdminUnit: item.AdminUnit,
          subCounty: item?.AdminUnit?.subCounty,
          ward: item?.AdminUnit?.ward,
          town: item?.AdminUnit?.name,
        }));
        setData({ ...result, data: transformedData });
      }
    } catch (error) {
      console.error("Error fetching valuation data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    setPaginationModel({ ...paginationModel, page: 0 });
  };

  const handleRowClick = (params) => {
    setSelectedValuation(params.row);
    setOpenDialog(true);
  };

  const columns = [
    {
      field: "index",
      headerName: "No",
      width: 70,
      renderCell: (params) => {
        const index = data.data.findIndex((e) => e.id === params?.row?.id);
        return (
          <Chip
            label={index + 1 + paginationModel.page * paginationModel.pageSize}
          />
        );
      },
    },
    { field: "srNo", headerName: "SN" },
    { field: "plotNo", headerName: "Plot No", flex: 0.5 },
    { field: "lrNo", headerName: "LR No", flex: 0.5 },
    { field: "oldLrNo", headerName: "Old LR No", flex: 0.5 },
    {
      field: "subCounty",
      headerName: "Subcounty",
      flex: 1,
    },
    {
      field: "ward",
      headerName: "Ward",
      flex: 1,
    },
    {
      field: "town",
      headerName: "Town",
      flex: 1,
    },
    { field: "location", headerName: "Location", flex: 1 },
    { field: "size", headerName: "Size", flex: 0.5 },
    { field: "cgh", headerName: "CGH", flex: 0.5 },
    { field: "private", headerName: "Private", flex: 0.5 },
  ];

  const renderDialogContent = (valuation) => {
    if (!valuation) return null;

    const formatField = (key, value) => {
      if (key === "AdminUnit") {
        return (
          <>
            {Object.entries(value)
              .filter(
                ([k]) =>
                  !k.includes("id") &&
                  !k.includes("createdAt") &&
                  !k.includes("updatedAt")
              )
              .map(([k, v]) => (
                <TableRow key={k}>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ fontWeight: "bold" }}
                  >
                    {k.toUpperCase()}
                  </TableCell>
                  <TableCell>{v}</TableCell>
                </TableRow>
              ))}
          </>
        );
      }
      return (
        <TableRow key={key}>
          <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
            {key.toUpperCase()}
          </TableCell>
          <TableCell>{value}</TableCell>
        </TableRow>
      );
    };

    return (
      <TableContainer component={Paper}>
        <Table size="small">
          <TableBody>
            {Object.entries(valuation)
              .filter(([key]) => !key.includes("id") && key !== "adminUnitId")
              .map(([key, value]) => formatField(key, value))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Box>
      <Card sx={{ boxShadow: "0px 8px 32px #60606040", p: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <Typography variant="h6" flexGrow={1}>
            Valuation Roll
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <FormControl>
              <InputLabel size="small">Search by...</InputLabel>
              <Select
                label="Search by..."
                size="small"
                onChange={(e) => setColumn(e.target.value)}
                value={column}
              >
                <MenuItem value="plotNo">Plot No</MenuItem>
                <MenuItem value="lrNo">LR Number</MenuItem>
                <MenuItem value="use">Land Use</MenuItem>
              </Select>
            </FormControl>
            <TextField
              variant="outlined"
              size="small"
              label="Search..."
              value={searchValue}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </Box>
        </Box>

        <DataGrid
          rows={data?.data || []}
          columns={columns}
          initialState={{
            columns: {
              columnVisibilityModel: {
                srNo: false,
                lrNo: false,
                oldLrNo: false,
                location: false,
              },
            },
          }}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          loading={loading}
          onRowClick={handleRowClick}
          rowCount={data?.total ?? 0}
          paginationMode="server"
          disableSelectionOnClick
          autoHeight
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Card>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <Box
          sx={{
            display: "flex",
            px: 2,
            pt: 2,
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">Valuation Details</Typography>{" "}
          <Button
            variant="outlined"
            size="small"
            color="error"
            onClick={() => setOpenDialog(false)}
          >
            Close
          </Button>{" "}
        </Box>
        <Divider sx={{ my: 1 }} />
        <DialogContent>{renderDialogContent(selectedValuation)}</DialogContent>
      </Dialog>
    </Box>
  );
};

export default DataValuations;
