import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Chip,
  Alert,
  Divider,
  Stack,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TextField,
  Grid2,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ReviewIcon from "@mui/icons-material/Visibility";

const MDValuations = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [reviewDialogOpen, setReviewDialogOpen] = useState(false);
  const [creating, setCreating] = useState(false);
  const [valuationData, setValuationData] = useState({
    plotNo: "",
    adminUnitId: "",
    srNo: "",
    lrNo: "",
    oldLrNo: "",
    vbNo: "",
    location: "",
    size: "",
    cgh: "",
    private: "",
    tenure: "",
    use: "",
    remarks: "",
  });
  const [reviewData, setReviewData] = useState(null);
  const [adminUnits, setAdminUnits] = useState(null);
  const [selectedAdminUnitId, setSelectedAdminUnitId] = useState("");
  const [column, setColumn] = useState("name");
  const [searchValue, setSearchValue] = useState("");
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchAdminUnits(); // Fetch admin units
  }, []);

  useEffect(() => {
    if (searchValue === "") {
      fetchValuationData();
    }
  }, [paginationModel, searchValue]);

  const fetchAdminUnits = async () => {
    try {
      const response = await fetch("/api/adminunits");
      const data = await response.json();
      setAdminUnits(data);
    } catch (error) {
      console.error("Error fetching admin units:", error);
    }
  };

  const fetchValuationData = async () => {
    try {
      setData(null);
      setLoading(true);
      const response = await fetch(
        `/api/tempValuation?offset=${
          paginationModel.page * paginationModel.pageSize
        }&limit=${paginationModel.pageSize}&includeAdminUnits=true`
      );
      const data = await response.json();
      if (response.ok) {
        const transformedData = data.data.map((item) => ({
          ...item,
          AdminUnit: item.AdminUnit,
          subCounty: item?.AdminUnit?.subCounty,
          ward: item?.AdminUnit?.ward,
          town: item?.AdminUnit?.name,
        }));
        setData({ ...data, data: transformedData });
      }
    } catch (error) {
      console.error("Error fetching valuation data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAdminUnitChange = (event) => {
    setSelectedAdminUnitId(event.target.value);
    setValuationData((prev) => ({ ...prev, adminUnitId: event.target.value }));
  };

  const handleSearch = async (value) => {
    if (value !== "") {
      setSearchValue(value);
      setData(null);
      setPaginationModel({ ...paginationModel, page: 0 });
      setLoading(true);
      try {
        const response = await fetch(
          `/api/tempValuation?offset=${
            paginationModel.page * paginationModel.pageSize
          }&limit=${
            paginationModel.pageSize
          }&${column}=${value}&includeAdminUnits=true`
        );
        const data = await response.json();
        if (response.ok) {
          setData(data);
        }
      } catch (error) {
        console.error("Error during search:", error);
      } finally {
        setLoading(false);
      }
    } else {
      setSearchValue("");
      fetchValuationData();
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setValuationData({
      plotNo: "",
      adminUnitId: "",
      srNo: "",
      lrNo: "",
      oldLrNo: "",
      vbNo: "",
      location: "",
      size: "",
      cgh: "",
      private: "",
      tenure: "",
      use: "",
      remarks: "",
    });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedAdminUnitId("");
    setError("");
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setValuationData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSaveValuation = async () => {
    if (
      !valuationData.plotNo ||
      !valuationData.adminUnitId ||
      !valuationData.lrNo ||
      !valuationData.size ||
      !valuationData.tenure ||
      !valuationData.use
    ) {
      setError("Please fill in all required fields.");
      return;
    }

    setCreating(true);
    try {
      const method = valuationData.id ? "PUT" : "POST";
      const response = await fetch(
        `/api/tempValuation${valuationData.id ? `/${valuationData.id}` : ""}`,
        {
          method,
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(valuationData),
        }
      );
      const body = await response.json();
      if (response.ok) {
        setError(body?.message);
        setTimeout(() => {
          fetchValuationData();
          handleCloseDialog();
        }, 1000);
      } else {
        setError(body?.message);
      }
    } catch (error) {
      setError("Error saving valuation. Please try again.");
    } finally {
      setCreating(false);
    }
  };

  const handleEditValuation = (valuation) => {
    setValuationData(valuation);
    setSelectedAdminUnitId(valuation.adminUnitId);
    setOpenDialog(true);
  };

  const handleDeleteValuation = async (id) => {
    if (window.confirm("Are you sure you want to delete this valuation?")) {
      try {
        const response = await fetch(`/api/tempValuation/${id}`, {
          method: "DELETE",
        });
        const body = await response.json();
        if (response.ok) {
          setError(body?.message);
          fetchValuationData();
        } else {
          setError(body?.message);
        }
      } catch (error) {
        setError("Error deleting valuation. Please try again.");
      }
    }
  };

  const handleReview = async (valuation) => {
    try {
      setValuationData(valuation);
      const response = await fetch(
        `/api/valuations?plotNo=${valuation.plotNo}`
      );
      const data = await response.json();
      if (response.ok) {
        setReviewData(data.total > 0 ? data?.data[0] : null);
        setReviewDialogOpen(true);
      }
    } catch (error) {
      console.error("Error fetching review data:", error);
    }
  };

  const handleUpdateValuation = async () => {
    try {
      const url = reviewData
        ? `/api/valuations/${reviewData.id}`
        : `/api/valuations`;
      const method = reviewData ? "PUT" : "POST";

      const response = await fetch(url, {
        method: method,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(valuationData),
      });

      const body = await response.json();
      if (response.ok) {
        setError(body?.message || "Valuation updated successfully!");
        setReviewDialogOpen(false);
        fetchValuationData();
      } else {
        setError(body?.message || "Error updating valuation.");
      }
    } catch (error) {
      console.error("Error updating valuation:", error);
      setError("Network error. Please try again.");
    }
  };

  const columns = [
    {
      field: "index",
      headerName: "No",
      width: 70,
      renderCell: (params) => {
        const index = data.data.findIndex((e) => e.id === params?.row?.id);
        return (
          <Chip
            label={index + 1 + paginationModel.page * paginationModel.pageSize}
          />
        );
      },
    },
    { field: "plotNo", headerName: "Plot No", flex: 0.5 },
    { field: "lrNo", headerName: "LR No", flex: 0.5 },
    { field: "oldLrNo", headerName: "Old LR No", flex: 0.5 },
    {
      field: "subCounty",
      headerName: "Subcounty",
      flex: 1,
    },
    {
      field: "ward",
      headerName: "Ward",
      flex: 1,
    },
    {
      field: "town",
      headerName: "Town",
      flex: 1,
    },
    { field: "location", headerName: "Location", flex: 1 },
    { field: "size", headerName: "Size", flex: 0.5 },
    { field: "cgh", headerName: "CGH", flex: 0.5 },
    { field: "private", headerName: "Private", flex: 0.5 },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <Box display="flex" gap={1}>
          <IconButton onClick={() => handleReview(params.row)}>
            <ReviewIcon />
          </IconButton>
          <IconButton onClick={() => handleEditValuation(params.row)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDeleteValuation(params.row.id)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2, gap: 2 }}>
        <Box flexGrow={1}>
          <Typography variant="title">Mapped Valuation</Typography>
        </Box>
        <FormControl>
          <InputLabel size="small">Search by...</InputLabel>
          <Select
            label="Search by..."
            size="small"
            onChange={(e) => setColumn(e.target.value)}
            value={column}
          >
            <MenuItem value="name">Name</MenuItem>
            <MenuItem value="subCounty">Sub County</MenuItem>
            <MenuItem value="ward">Ward</MenuItem>
          </Select>
        </FormControl>
        <TextField
          variant="outlined"
          size="small"
          label="Search..."
          value={searchValue}
          onChange={(e) => handleSearch(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleOpenDialog}
          sx={{ color: "white", textTransform: "capitalize" }}
        >
          Create New
        </Button>
      </Box>

      <div style={{ width: "100%" }}>
        <DataGrid
          rows={data ? data.data : []}
          columns={columns}
          initialState={{
            columns: {
              columnVisibilityModel: {
                srNo: false,
                lrNo: false,
                oldLrNo: false,
                location: false,
              },
            },
          }}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          rowCount={data?.total ?? 0}
          paginationMode="server"
          loading={loading}
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </div>
      <Dialog fullWidth open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {valuationData.id ? "Edit Valuation" : "New Valuation"}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Stack spacing={2}>
            <FormControl fullWidth>
              <InputLabel>Admin Unit</InputLabel>
              <Select
                value={selectedAdminUnitId ?? valuationData.adminUnitId}
                onChange={handleAdminUnitChange}
                label="Admin Unit"
              >
                {adminUnits?.data.map((unit) => (
                  <MenuItem key={unit.id} value={unit.id}>
                    {unit.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Grid2 container spacing={2}>
              <Grid2 size={{ xs: 12, md: 4 }}>
                <TextField
                  fullWidth
                  label="Plot No"
                  name="plotNo"
                  value={valuationData.plotNo}
                  onChange={handleInputChange}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 4 }}>
                <TextField
                  fullWidth
                  label="LR Number"
                  name="lrNo"
                  value={valuationData.lrNo}
                  onChange={handleInputChange}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 4 }}>
                <TextField
                  fullWidth
                  label="Old LR Number"
                  name="oldLrNo"
                  value={valuationData.oldLrNo}
                  onChange={handleInputChange}
                />
              </Grid2>
            </Grid2>
            <TextField
              fullWidth
              label="Location"
              name="location"
              value={valuationData.location}
              onChange={handleInputChange}
            />
            <Grid2 container spacing={2}>
              <Grid2 size={{ xs: 12, md: 4 }}>
                <TextField
                  fullWidth
                  label="Size"
                  name="size"
                  type="number"
                  value={valuationData.size}
                  onChange={handleInputChange}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 4 }}>
                <TextField
                  fullWidth
                  label="CGH"
                  name="cgh"
                  type="number"
                  value={valuationData.cgh}
                  onChange={handleInputChange}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 4 }}>
                <TextField
                  fullWidth
                  label="Private"
                  name="private"
                  type="number"
                  value={valuationData.private}
                  onChange={handleInputChange}
                />
              </Grid2>
            </Grid2>
            <Grid2 container spacing={2}>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <FormControl fullWidth>
                  <InputLabel>Tenure</InputLabel>
                  <Select
                    name="tenure"
                    value={valuationData.tenure}
                    onChange={handleInputChange}
                    label="Tenure"
                  >
                    <MenuItem value="Freehold">Freehold</MenuItem>
                    <MenuItem value="Leasehold">Leasehold</MenuItem>
                  </Select>
                </FormControl>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <FormControl fullWidth>
                  <InputLabel>Land Use</InputLabel>
                  <Select
                    name="use"
                    value={valuationData.use}
                    onChange={handleInputChange}
                    label="Land Use"
                  >
                    <MenuItem value="Commercial">Commercial</MenuItem>
                    <MenuItem value="Industrial">Industrial</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid2>
            </Grid2>
            <TextField
              fullWidth
              multiline
              rows={3}
              label="Remarks"
              name="remarks"
              value={valuationData.remarks}
              onChange={handleInputChange}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            color="secondary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSaveValuation}
            color="primary"
            variant="contained"
            disabled={creating}
          >
            {creating ? "Processing..." : "Save"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        open={reviewDialogOpen}
        onClose={() => setReviewDialogOpen(false)}
      >
        <DialogTitle>Review Valuation</DialogTitle>
        <Divider />
        <DialogContent>
          {valuationData ? (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Field</TableCell>
                    <TableCell>Temp Valuation</TableCell>
                    <TableCell>Current Valuation</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(valuationData || {})
                    .filter(
                      (key) =>
                        key !== "id" &&
                        key !== "valuationId" &&
                        key !== "deletedAt" &&
                        key !== "index" &&
                        key !== "adminUnitId"
                    )
                    .map((key) => (
                      <TableRow key={key}>
                        <TableCell
                          sx={{
                            textTransform: "uppercase",
                            fontWeight: "bold",
                          }}
                          padding="none"
                        >
                          {key}
                        </TableCell>
                        <TableCell padding="none">
                          {valuationData &&
                          typeof valuationData[key] === "object" ? (
                            <Table>
                              <TableBody>
                                {Object.entries(valuationData[key] || {})
                                  .filter(([subKey]) => subKey !== "id")
                                  .map(([subKey, subValue]) => (
                                    <TableRow key={subKey}>
                                      <TableCell
                                        sx={{
                                          textTransform: "uppercase",
                                          fontWeight: "bold",
                                        }}
                                        padding="none"
                                      >
                                        {subKey}
                                      </TableCell>
                                      <TableCell padding="none">
                                        {subValue || ""}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          ) : valuationData ? (
                            valuationData[key] || ""
                          ) : (
                            ""
                          )}
                        </TableCell>
                        <TableCell padding="none">
                          {reviewData && typeof reviewData[key] === "object" ? (
                            <Table>
                              <TableBody>
                                {Object.entries(reviewData[key] || {})
                                  .filter(([subKey]) => subKey !== "id")
                                  .map(([subKey, subValue]) => (
                                    <TableRow key={subKey}>
                                      <TableCell
                                        sx={{
                                          textTransform: "uppercase",
                                          fontWeight: "bold",
                                        }}
                                        padding="none"
                                      >
                                        {subKey}
                                      </TableCell>
                                      <TableCell padding="none">
                                        {subValue || ""}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          ) : reviewData ? (
                            reviewData[key] || ""
                          ) : (
                            ""
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>No data available for review.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setReviewDialogOpen(false)}
            color="secondary"
            variant="outlined"
          >
            Close
          </Button>
          <Button
            onClick={() => handleUpdateValuation()}
            color="primary"
            variant="contained"
          >
            {reviewData == null ? "Create Valuation" : "Update Valuation"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MDValuations;
