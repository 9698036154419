import React, { useState } from "react";
import { ButtonGroup, Button, Box, Card } from "@mui/material";
import Users from "./Users";
import Agents from "./Agents";

export default function UsersHome(props) {
  const [selected, setSelected] = useState("Admin Users");

  const handleNavigation = (path) => {
    setSelected(path);
  };

  return (
    <Box >
      <ButtonGroup aria-label="outlined primary button group">
        <Button
          variant={selected == "Admin Users" ? "contained" : "outlined"}
          sx={{ textTransform: "capitalize" }}
          onClick={() => handleNavigation("Admin Users")}
        >
          Staff
        </Button>
        <Button
          variant={selected == "Agents" ? "contained" : "outlined"}
          sx={{ textTransform: "capitalize" }}
          onClick={() => handleNavigation("Agents")}
        >
          Enumerators
        </Button>
      </ButtonGroup>
      <Box
        sx={{
          padding: "16px",
          borderRadius: "16px",
          boxShadow: "0px 4px 20px #60606030",
        }}
        component={Card}
        mt={2}
      >
        {selected == "Admin Users" && <Users />}
        {selected == "Agents" && <Agents />}
      </Box>
    </Box>
  );
}
