import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Collapse,
  Divider,
  Stack,
  Pagination,
  Chip,
} from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";

export default function Support({ user }) {
  const [enquiries, setEnquiries] = useState([]);
  const [totalEnquiries, setTotalEnquiries] = useState(0);
  const [expandedEnquiryId, setExpandedEnquiryId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 10;

  useEffect(() => {
    const fetchEnquiries = async () => {
      try {
        const offset = (currentPage - 1) * limit;
        const response = await fetch(
          `/api/enquiries?userId=${user?.id}&offset=${offset}&limit=${limit}`
        );
        if (response.ok) {
          const data = await response.json();
          setEnquiries(data.data);
          setTotalEnquiries(data.total);
        }
      } catch (error) {
        console.error("Error fetching enquiries:", error);
      }
    };

    fetchEnquiries();
  }, [user?.id, currentPage]);

  const handleExpandClick = (enquiryId) => {
    setExpandedEnquiryId((prevId) => (prevId === enquiryId ? null : enquiryId));
  };

  const handlePageChange = (_, page) => {
    setCurrentPage(page);
  };

  return (
    <Box>
      <Stack spacing={2}>
        {enquiries.map((enquiry) => (
          <Card
            key={enquiry.ID}
            sx={{
              position: "relative",
              boxShadow: "0px 4px 16px #60606040",
              borderRadius: "8px",
            }}
          >
            <CardContent sx={{ mb: 0, pb: 0 }}>
              <Typography display="block" variant="subtitle">
                {enquiry.title}
              </Typography>

              <Chip
                sx={{ position: "absolute", bottom: "1.5em", right: "1em" }}
                label={enquiry.status ? "Resolved" : "Pending"}
                color={enquiry.status ? "success" : "warning"}
              />
              <Typography
                sx={{ position: "absolute", top: "1.5em", right: "1em" }}
                variant="body2"
                color="text.secondary"
              >
                {new Date(enquiry.createdAt).toLocaleString()}
              </Typography>
              <Button
                sx={{ textTransform: "capitalize" }}
                variant="outlined"
                size="small"
                onClick={() => handleExpandClick(enquiry.ID)}
              >
                {expandedEnquiryId === enquiry.ID ? (
                  <ArrowForwardIos
                    sx={{ rotate: "-90deg", fontSize: "small", mr: 1 }}
                  />
                ) : (
                  <ArrowForwardIos
                    sx={{ rotate: "90deg", fontSize: "small", mr: 1 }}
                  />
                )}{" "}
                {expandedEnquiryId === enquiry.ID
                  ? "Hide Details"
                  : "View Details"}
              </Button>
            </CardContent>
            <Collapse
              in={expandedEnquiryId === enquiry.ID}
              timeout="auto"
              unmountOnExit
            >
              <CardContent>
                <Divider sx={{ mb: 1 }} />
                <Typography variant="body2" color="text.secondary">
                  <strong style={{ fontWeight: 500 }}>Category:</strong>{" "}
                  {enquiry.category}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <strong style={{ fontWeight: 500 }}>Name:</strong>{" "}
                  {enquiry.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <strong style={{ fontWeight: 500 }}>Phone:</strong>{" "}
                  {enquiry.phone}
                </Typography>
                <br />
                <Typography
                  color="primary.main"
                  variant="subtitle"
                  sx={{ mt: 2 }}
                >
                  Reported Issue
                </Typography>
                <Divider />
                <Typography variant="body1" sx={{ mb: 2 }}>
                  {enquiry.message}
                </Typography>
                {enquiry.resolution && (
                  <>
                    <Typography
                      color="primary.main"
                      variant="subtitle"
                      sx={{ mt: 2 }}
                    >
                      Resolution
                    </Typography>
                    <Divider />
                    <Typography variant="body1" sx={{ mb: 2 }}>
                      {enquiry.resolution}
                    </Typography>{" "}
                  </>
                )}
              </CardContent>
            </Collapse>
          </Card>
        ))}
      </Stack>
      <Pagination
        count={Math.ceil(totalEnquiries / limit)}
        page={currentPage}
        onChange={handlePageChange}
        sx={{ mt: 3 }}
      />
    </Box>
  );
}
