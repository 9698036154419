export const myData = [
  {
    name: "OpenStreetMap",
    attribution:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    url: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
  },
  {
    name: "TopoMap",
    attribution:
      'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
    url: "https://{a-c}.tile.opentopomap.org/{z}/{x}/{y}.png",
  },
  {
    name: "WorldStreetMap",
    attribution:
      "Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012",
    url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
  },
  {
    name: "Satellite",
    attribution:
      "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community",
    url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
  },
];

export const getMonths = [
  { month: "January", code: "01", days: 31 },
  { month: "February", code: "02", days: 28 },
  { month: "March", code: "03", days: 31 },
  { month: "April", code: "04", days: 30 },
  { month: "May", code: "05", days: 31 },
  { month: "June", code: "06", days: 30 },
  { month: "July", code: "07", days: 31 },
  { month: "August", code: "08", days: 31 },
  { month: "September", code: "09", days: 30 },
  { month: "October", code: "10", days: 31 },
  { month: "November", code: "11", days: 30 },
  { month: "December", code: "12", days: 31 },
];

export const countyData = [
  {
    Kasipul: [
      "West Kasipul",
      "South Kasipul",
      "Central Kasipul",
      "East Kamagak",
      "West Kamagak",
    ],
  },
  {
    "Kabondo Kasipul": [
      "Kabondo East",
      "Kabondo West",
      "Kokwanyo/kakelo",
      "Kojwach",
    ],
  },
  {
    Karachuonyo: [
      "West Karachuonyo",
      "North Karachuonyo",
      "Central",
      "Kanyaluo",
      "Kibiri",
      "Wang'chieng",
      "Kendu Bay Town",
    ],
  },
  {
    Rangwe: ["Kagan", "Kochia", "West Gem", "East Gem"],
  },
  {
    "Homa Bay Town": [
      "Homa Bay Central",
      "Homa Bay Arujo",
      "Homa Bay East",
      "Homa Bay West",
    ],
  },
  {
    Ndhiwa: [
      "Kwabwai",
      "Kanyadoto",
      "Kanyikela",
      "Kabouch North",
      "Kabuoch South/pala",
      "Kanyamwa Kosewe",
      "Kanyamwa Kologi",
    ],
  },
  {
    Mbita: ["Mfangano Island", "Rusinga Island", "Kasgunga", "Gembe", "Lambwe"],
  },
  {
    Suba: [
      "Gwassi South",
      "Gwassi North",
      "Kaksingiri West",
      "Ruma-kaksingiri East",
    ],
  },
];

export const adminUnitsData = {
  "Homa Bay Town": [
    "Homa Bay West",
    "Homa Bay Central",
    "Homa Bay East",
    "Homa Bay Arujo",
  ],
  Ndhiwa: [
    "Kanyamwa Kologi",
    "Kanyamwa Kosewe",
    "Kabuoch South/Pala",
    "Kwabwai",
    "Kanyadoto",
    "Kanyikela",
    "Kabondo East",
  ],
  "Rachuonyo East": [
    "West Karachuonyo",
    "Kibiri",
    "Kanyaluo",
    "Central",
    "Kojwach",
    "Kokwanyo/Kakelo",
  ],
  "Rachuonyo North": [
    "Wangchieng",
    "Kendu Bay Town",
    "North Karachuonyo",
    "Gwassi North",
  ],
  "Rachuonyo South": [
    "Rusinga Island",
    "West Kasipul",
    "West Kamagak",
    "South Kasipul",
    "East Kamagak",
    "Central Kasipul",
    "Kabondo West",
  ],
  Rangwe: ["East Gem", "West Gem", "Kagan", "Kochia"],
  "Suba North": ["Lambwe", "Gembe", "Kasgunga"],
};
