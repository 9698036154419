import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Card,
  Divider,
  Typography,
  Alert,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import InfoIcon from "@mui/icons-material/Info";
import SingleParcel from "../LandInfo/ParcelSearch/SingleParcel";
import dayjs from "dayjs";
const LandParcels = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [uploading, setUploading] = useState(false);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [zipFile, setZipFile] = useState(null);
  const [adminUnits, setAdminUnits] = useState(null);
  const [selectedAdminUnitId, setSelectedAdminUnitId] = useState("");
  const [column, setColumn] = useState("plotNo");
  const [searchValue, setSearchValue] = useState("");
  const [changed, setChanged] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [loading, setLoading] = useState(false);
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const [selectedParcel, setSelectedParcel] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editedParcel, setEditedParcel] = useState(null);

  useEffect(() => {
    fetchAdminUnits();
  }, []);

  useEffect(() => {
    if (searchValue === "") {
      fetchLandParcels();
    }
  }, [paginationModel.page, paginationModel.pageSize, searchValue]);

  const fetchLandParcels = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `/api/landparcels?offset=${
          paginationModel.page * paginationModel.pageSize
        }&limit=${
          paginationModel.pageSize
        }&includeAdminUnits=true&includeValuations=true`
      );
      const data = await response.json();
      if (response.ok) {
        setData(data);
      }
    } catch (error) {
      console.error("Error fetching land parcels:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAdminUnits = async () => {
    try {
      const response = await fetch("/api/adminunits");
      const data = await response.json();
      if (response.ok) {
        setAdminUnits(data);
      }
    } catch (error) {
      console.error("Error fetching admin units:", error);
    }
  };

  const handleSearch = async (value) => {
    if (value !== "") {
      setSearchValue(value);
      setLoading(true);
      try {
        const response = await fetch(
          `/api/landparcels?offset=0&limit=${paginationModel.pageSize}&${column}=${value}&includeAdminUnits=true&includeValuations=true`
        );
        const data = await response.json();
        if (response.ok) {
          setData(data);
          setPaginationModel({ ...paginationModel, page: 0 });
        }
      } catch (error) {
        console.error("Error searching land parcels:", error);
      } finally {
        setLoading(false);
      }
    } else {
      setSearchValue("");
      setChanged(!changed);
    }
  };

  const handleOpenUploadDialog = () => setOpenUploadDialog(true);
  const handleCloseUploadDialog = () => {
    setOpenUploadDialog(false);
    setError("");
    setSelectedAdminUnitId("");
    setZipFile(null);
  };
  const handleFileChange = (event) => setZipFile(event.target.files[0]);

  const handleAdminUnitChange = (event) =>
    setSelectedAdminUnitId(event.target.value);

  const handleUpload = async () => {
    if (!zipFile || !selectedAdminUnitId) {
      setError("Please select a zip file and an Admin Unit.");
      return;
    }
    setUploading(true);
    const formData = new FormData();
    formData.append("shapefile", zipFile);
    formData.append("adminUnitId", selectedAdminUnitId);
    setError("");
    try {
      const response = await fetch("/api/landparcels", {
        method: "POST",
        body: formData,
      });
      const body = await response.json();
      if (response.ok) {
        setError(body?.message);
        setTimeout(() => {
          fetchLandParcels();
          handleCloseUploadDialog();
        }, 1000);
      } else {
        setError(body?.message);
      }
    } catch (error) {
      setError(
        "Oops, something went wrong. Please check your connection and try again."
      );
    } finally {
      setUploading(false);
    }
  };

  const handleOpenInfoDialog = (parcel) => {
    setSelectedParcel(parcel);
    setOpenInfoDialog(true);
  };

  const handleCloseInfoDialog = () => {
    setOpenInfoDialog(false);
    setSelectedParcel(null);
  };

  const handleEditParcel = (parcel) => {
    setEditedParcel({ ...parcel });
    setOpenEditDialog(true);
    handleCloseInfoDialog();
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setEditedParcel(null);
  };

  const handleSaveEdit = async () => {
    try {
      const response = await fetch(`/api/landparcels/${editedParcel.id}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(editedParcel),
      });
      if (response.ok) {
        fetchLandParcels();
        handleCloseEditDialog();
      } else {
        const errorData = await response.json();
        setError(errorData.message || "Failed to update land parcel");
      }
    } catch (error) {
      console.error("Error updating land parcel:", error);
      setError("An error occurred while updating the land parcel");
    }
  };

  const columns = [
    {
      field: "index",
      headerName: "No",
      renderCell: (params) => {
        const index = data
          ? data.data.map((e) => e.id).indexOf(params?.row?.id)
          : 0;
        return (
          <Chip
            label={index + 1 + paginationModel.page * paginationModel.pageSize}
          />
        );
      },
    },
    { field: "plotNo", headerName: "Plot No", width: 120 },
    { field: "lrNo", headerName: "LR No" },
    { field: "frNo", headerName: "FR No" },
    { field: "type", headerName: "Boundary" },
    {
      field: "AdminUnit.subCounty",
      headerName: "SubCounty",
      flex: 1,
      renderCell: (params) => params.row.AdminUnit?.subCounty || "-",
    },
    {
      field: "AdminUnit.ward",
      headerName: "Ward",
      flex: 1,
      renderCell: (params) => params.row.AdminUnit?.ward || "-",
    },
    {
      field: "AdminUnit.name",
      headerName: "Town",
      flex: 1,
      renderCell: (params) => params.row.AdminUnit?.name || "-",
    },
    {
      field: "actions",
      headerName: "",
      renderCell: (params) => (
        <Box>
          <IconButton onClick={() => handleOpenInfoDialog(params.row)}>
            <InfoIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const EditForm = () => (
    <Dialog
      open={openEditDialog}
      onClose={handleCloseEditDialog}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Edit Land Parcel</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          margin="normal"
          label="Valuation ID"
          value={editedParcel?.valuationId || ""}
          onChange={(e) =>
            setEditedParcel({ ...editedParcel, valuationId: e.target.value })
          }
        />
        <TextField
          fullWidth
          margin="normal"
          label="Plot No"
          value={editedParcel?.plotNo || ""}
          onChange={(e) =>
            setEditedParcel({ ...editedParcel, plotNo: e.target.value })
          }
        />
        <TextField
          fullWidth
          margin="normal"
          label="LR No"
          value={editedParcel?.lrNo || ""}
          onChange={(e) =>
            setEditedParcel({ ...editedParcel, lrNo: e.target.value })
          }
        />
        <TextField
          fullWidth
          margin="normal"
          label="FR No"
          value={editedParcel?.frNo || ""}
          onChange={(e) =>
            setEditedParcel({ ...editedParcel, frNo: e.target.value })
          }
        />
        <TextField
          fullWidth
          margin="normal"
          label="Area"
          type="number"
          value={editedParcel?.area || ""}
          onChange={(e) =>
            setEditedParcel({
              ...editedParcel,
              area: parseFloat(e.target.value),
            })
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseEditDialog} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSaveEdit} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Box>
      <Card sx={{ boxShadow: "0px 8px 32px #60606040", borderRadius: 3, p: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2, gap: 2 }}>
          <Box flexGrow={1}>
            <Typography variant="h6">Plots</Typography>
          </Box>
          <FormControl sx={{ minWidth: { xs: "100%", md: "150px" } }}>
            <InputLabel size="small">Search by...</InputLabel>
            <Select
              label="Search by..."
              size="small"
              onChange={(e) => setColumn(e.target.value)}
              value={column}
            >
              <MenuItem value="plotNo">Plot Number</MenuItem>
              <MenuItem value="frNo">FR Number</MenuItem>
              <MenuItem value="lrNo">LR Number</MenuItem>
              <MenuItem value="area">Area</MenuItem>
            </Select>
          </FormControl>
          <TextField
            variant="outlined"
            size="small"
            label="Search..."
            sx={{ minWidth: { xs: "100%", md: "150px" } }}
            value={searchValue}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleOpenUploadDialog}
            sx={{ color: "white", textTransform: "capitalize" }}
          >
            Upload Shapefile (zip)
          </Button>
        </Box>

        <DataGrid
          rows={data ? data.data : []}
          columns={columns}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[5, 10, 25, 50, 100, 500, 1000]}
          rowCount={data ? data.total : 0}
          loading={loading}
          paginationMode="server"
          autoHeight
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Card>

      {/* Upload Shapefile Dialog */}
      <Dialog
        fullWidth
        open={openUploadDialog}
        onClose={handleCloseUploadDialog}
      >
        <DialogTitle>Upload Shapefile</DialogTitle>
        <Divider />
        <DialogContent>
          <Typography variant="body2" gutterBottom>
            Upload a zipped shapefile. It must contain key attributes (plotNo,
            area, lrNo, frNo).
          </Typography>

          <Select
            variant="outlined"
            value={selectedAdminUnitId}
            onChange={handleAdminUnitChange}
            displayEmpty
            fullWidth
            sx={{ mt: 1 }}
          >
            <MenuItem value="" disabled>
              Select Admin Unit
            </MenuItem>
            {adminUnits &&
              adminUnits.data.map((unit) => (
                <MenuItem key={unit.id} value={unit.id}>
                  {unit.name}
                </MenuItem>
              ))}
          </Select>

          <Box>
            <input
              style={{
                border: "1px solid #60606050",
                padding: "10px",
                width: "100%",
                cursor: "pointer",
                borderRadius: "8px",
                marginTop: "10px",
              }}
              type="file"
              accept=".zip"
              onChange={handleFileChange}
            />
          </Box>
          {error && (
            <Alert
              sx={{ mt: 2 }}
              color={error.includes("success") ? "success" : "warning"}
            >
              {error}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleCloseUploadDialog}
            color="secondary"
            sx={{ textTransform: "capitalize" }}
          >
            Cancel
          </Button>
          <Button
            sx={{ textTransform: "capitalize", color: "white" }}
            onClick={handleUpload}
            variant="contained"
            color="primary"
            disabled={uploading}
          >
            {uploading ? "Uploading shapefile..." : "Upload"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Info Dialog */}
      <Dialog
        fullWidth
        maxWidth="md"
        open={openInfoDialog}
        onClose={handleCloseInfoDialog}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            p: 2,
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">Land Parcel Details</Typography>
          <Button
            variant="outlined"
            onClick={handleCloseInfoDialog}
            color="primary"
          >
            Close
          </Button>
        </Box>
        <Divider />
        <DialogContent sx={{ p: 2 }}>
          {selectedParcel && (
            <TableContainer component={Paper}>
              <Table size="small">
                <TableBody>
                  {selectedParcel &&
                    Object.entries(selectedParcel)
                      .filter(
                        ([key]) =>
                          !key.includes("id") &&
                          key !== "adminUnitId" &&
                          key !== "valuationId"
                      )
                      .map(([key, value]) => {
                        if (key === "geom") {
                          return (
                            <TableRow key={key}>
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{ fontWeight: "bold" }}
                              >
                                {key.toUpperCase()}
                              </TableCell>
                              <TableCell colSpan={2}>
                                <SingleParcel
                                  geom={value}
                                  area={selectedParcel.area}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        }
                        if (key === "adminUnit" && value) {
                          return Object.entries(value)
                            .filter(([adminKey]) => !adminKey.includes("id"))
                            .map(([adminKey, adminValue]) => (
                              <TableRow key={`adminUnit-${adminKey}`}>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  {adminKey.toUpperCase()}
                                </TableCell>
                                <TableCell>{adminValue}</TableCell>
                              </TableRow>
                            ));
                        }
                        if (key === "valuation" && value) {
                          return (
                            <TableRow>
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{ fontWeight: "bold" }}
                              >
                                {key.toUpperCase()}
                              </TableCell>
                              <TableCell>
                                {Object.entries(value)
                                  .filter(
                                    ([valKey]) =>
                                      !valKey.includes("id") &&
                                      !valKey.includes("adminUnitId")
                                  )
                                  .map(([adminKey, adminValue]) => (
                                    <TableRow key={`adminUnit-${adminKey}`}>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{ fontWeight: "bold" }}
                                      >
                                        {adminKey.toUpperCase()}
                                      </TableCell>
                                      <TableCell>{adminValue}</TableCell>
                                    </TableRow>
                                  ))}
                              </TableCell>
                            </TableRow>
                          );
                        }
                        return (
                          <TableRow key={key}>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ fontWeight: "bold" }}
                            >
                              {key.toUpperCase()}
                            </TableCell>
                            <TableCell>
                              {typeof value === "object" && value !== null ? (
                                <Table size="small">
                                  <TableBody>
                                    {Object.entries(value)
                                      .filter(
                                        ([subKey]) => !["id"].includes(subKey)
                                      )
                                      .map(([subKey, subValue]) => (
                                        <TableRow key={subKey}>
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            sx={{ fontWeight: "bold" }}
                                          >
                                            {subKey.toUpperCase()}
                                          </TableCell>
                                          <TableCell>
                                            {subKey === "createdAt" ||
                                            subKey === "updatedAt"
                                              ? dayjs(subValue).format(
                                                  "YYYY-MM-DD HH:mm:ss"
                                                )
                                              : subValue}
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                  </TableBody>
                                </Table>
                              ) : (
                                value
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleEditParcel(selectedParcel)}
            color="primary"
            variant="outlined"
            startIcon={<EditIcon />}
          >
            Edit
          </Button>
        </DialogActions>
      </Dialog>

      <EditForm />
    </Box>
  );
};

export default LandParcels;
