import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import dayjs from "dayjs";
import QRCode from "qrcode";
import homabayLogo from "../../../assets/images/lg.png"; // Make sure to add the logo

const styles = StyleSheet.create({
  page: {
    padding: 40,
    fontSize: 12,
    fontFamily: "Helvetica",
  },
  header: {
    textAlign: "center",
    marginBottom: 20,
  },
  logo: {
    width: 80,
    height: 80,
    marginBottom: 10,
    alignSelf: "center",
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 14,
    marginBottom: 5,
  },
  section: {
    marginBottom: 20,
  },
  sectionTitle: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 10,
    borderBottom: "1 solid black",
    paddingBottom: 5,
  },
  table: {
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    marginBottom: 10,
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
  },
  tableHeader: {
    backgroundColor: "#f0f0f0",
    fontWeight: "bold",
  },
  tableCell: {
    padding: 5,
    borderRightWidth: 1,
    borderRightColor: "#000",
    flex: 1,
  },
  footer: {
    position: "absolute",
    bottom: 40,
    left: 40,
    right: 40,
  },
  qrCode: {
    width: 150,
    height: 150,
    alignSelf: "center",
    marginBottom: 10,
  },
  disclaimer: {
    fontSize: 10,
    color: "#666",
    textAlign: "center",
    marginTop: 10,
  },
});

const SearchCertificate = ({ search, parcel, ownership }) => {
  // Generate QR code data URL
  const qrCodeData = `https://lands.homabay.go.ke/verify/${search?.reference}`;
  const [qrCodeUrl, setQrCodeUrl] = React.useState("");

  React.useEffect(() => {
    QRCode.toDataURL(qrCodeData).then(setQrCodeUrl);
  }, [qrCodeData]);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Image src={homabayLogo} style={styles.logo} />
          <Text style={styles.title}>THE COUNTY GOVERNMENT OF HOMA BAY</Text>
          <Text style={styles.subtitle}>
            DEPARTMENT OF LANDS, PHYSICAL PLANNING,
          </Text>
          <Text style={styles.subtitle}>HOUSING AND URBAN DEVELOPMENT</Text>
          <Text style={[styles.title, { marginTop: 20 }]}>
            CERTIFICATE OF OFFICIAL SEARCH
          </Text>
          <Text style={styles.subtitle}>Reference: {search?.reference}</Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>
            <Text style={{ fontWeight: "bold" }}>Title No.</Text>{" "}
          </Text>
        </View>
        <View style={styles.section}>
          <Text>
            On the {dayjs().format("DD")} day of {dayjs().format("MMMM YYYY")},
            the following subsisting entries on the register of the
            above-mentioned title were found:
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>PART A - Property Section</Text>
          <View style={styles.table}>
            <View style={[styles.tableRow, styles.tableHeader]}>
              <View style={styles.tableCell}>
                <Text>Plot No</Text>
              </View>
              <View style={styles.tableCell}>
                <Text>Area (Ha)</Text>
              </View>
              <View style={styles.tableCell}>
                <Text>Location</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCell}>
                <Text>{search?.plotNumber}</Text>
              </View>
              <View style={styles.tableCell}>
                <Text>{parcel?.size}</Text>
              </View>
              <View style={styles.tableCell}>
                <Text>{parcel?.location}</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>
            PART B - Proprietorship Section
          </Text>
          <View style={styles.table}>
            <View style={[styles.tableRow, styles.tableHeader]}>
              <View style={styles.tableCell}>
                <Text>Owner Name</Text>
              </View>
              <View style={styles.tableCell}>
                <Text>ID Number</Text>
              </View>
              <View style={styles.tableCell}>
                <Text>Registration Date</Text>
              </View>
            </View>
            {ownership?.owners?.map((owner, index) => (
              <View key={index} style={styles.tableRow}>
                <View style={styles.tableCell}>
                  <Text>{owner.name}</Text>
                </View>
                <View style={styles.tableCell}>
                  <Text>{owner.idNumber}</Text>
                </View>
                <View style={styles.tableCell}>
                  <Text>
                    {dayjs(owner.registrationDate).format("DD/MM/YYYY")}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </View>

        <View style={styles.footer}>
          <Image src={qrCodeUrl} style={styles.qrCode} />
          <Text style={styles.disclaimer}>
            This is an electronically generated certificate. No signature is
            required.
          </Text>
          <Text style={styles.disclaimer}>
            Verify this certificate by scanning the QR code or visiting
            lands.homabay.go.ke/verify/{search?.reference}
          </Text>
          <Text style={styles.disclaimer}>
            Generated on {dayjs().format("MMMM D, YYYY [at] h:mm A")}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default SearchCertificate;
