import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Divider,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Button,
  Chip,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

export default function Payments() {
  const [data, setData] = useState(null);
  const [offset, setOffset] = useState(0);
  const [isloading, setIsLoading] = useState(false);

  const [showFilters, setShowFilters] = useState(false);
  const today = new Date();
  const [start, setStart] = useState(`${today.getFullYear()}-01-01`);
  const [end, setEnd] = useState(today.toISOString().split("T")[0]);
  const [payMethod, setPayMethod] = useState();
  const [editing, setEditing] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [filter, setFilter] = useState(false);
  const [column, setColumn] = useState("OwnerName");
  const [fcolumn, setFColumn] = useState(null);
  const [foperator, setFOperator] = useState(null);
  const [fvalue, setFValue] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  useEffect(() => {
    if (!start || !end || !payMethod) {
      setIsLoading(true);
      fetch(
        `/api/billings?includeAdminUnits=true&includeValuations=true&includeOwners=true&limit=${
          paginationModel.pageSize
        }&offset=${paginationModel.page * paginationModel.pageSize}`
      )
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          console.log(data?.data);
          setIsLoading(false);
          setData(data?.data);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(true);
      fetch(
        `/api/billings/filter${start ? `/${start}` : ""}${
          end ? `/${end}` : ""
        }${payMethod ? `/${payMethod}` : ""}`
      )
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setIsLoading(false);
          setData(data);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }
  }, [refresh, start, end, payMethod]);

  function searchCustomer(v) {
    setIsLoading(true);
    fetch(`/api/billing/search/${column}/${v}/${offset}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setIsLoading(false);
        if (data?.data?.length > 0) {
          const formattedData = data.data.map((item) => ({
            ...item,
            Name: item?.Ownership?.Name || "N/A",
          }));
          setData({ ...data, data: formattedData });
        } else setData(null);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }

  const columns = [
    {
      field: "index",
      headerName: "SN",
      width: 70,
      renderCell: (params) => {
        const index = data.findIndex((e) => e.id === params?.row?.id);
        return (
          <Chip
            label={index + 1 + paginationModel.page * paginationModel.pageSize}
          />
        );
      },
    },
    { field: "Name", headerName: "Name", flex: 1 },
    { field: "plotNo", headerName: "Plot No.", flex: 1 },
    { field: "dateBilled", headerName: "Date Billed", flex: 1 },
    {
      field: "billingAmount",
      headerName: "Invoiced Amount",
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={(params.value - 0).toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
        />
      ),
    },
    {
      field: "landRate",
      headerName: "Land Rates",
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={(params.value - 0).toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
        />
      ),
    },
    {
      field: "landRent",
      headerName: "Ground Rent",
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={(params.value - 0).toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
        />
      ),
    },
    {
      field: "arrears",
      headerName: "Arrears",
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={(params.value - 0).toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
        />
      ),
    },
    { field: "paymentMethod", headerName: "Payment Method", flex: 1 },
  ];

  return (
    <Box>
      <Paper>
        <Box p={1}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Box>
              <Typography variant="h6">Payments</Typography>
              <Typography variant="body2">
                Keep track of billing statistics
              </Typography>
            </Box>
            {/* <Box display="flex" alignItems="center" gap={1}>
              <FormControl fullWidth variant="standard" margin="dense">
                <InputLabel id="columns-label">Columns</InputLabel>
                <Select
                  labelId="columns-label"
                  label="columns"
                  onChange={(e) => {
                    console.log(e.target.value);
                    setColumn(e.target.value);
                  }}
                  defaultValue={""}
                >
                  <MenuItem value="OwnerName">Name</MenuItem>
                  <MenuItem value="NewPlotNumber">Plot No</MenuItem>
                  <MenuItem value="IDNumber">ID No</MenuItem>
                  <MenuItem value="LRNo">LR No</MenuItem>
                  <MenuItem value="MobileNumber">Phone No</MenuItem>
                  <MenuItem value="Zone">Zone</MenuItem>
                  <MenuItem value="SubCounty">SubCounty</MenuItem>
                  <MenuItem value="Ward">Ward</MenuItem>
                  <MenuItem value="Month">Month</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="Search"
                variant="standard"
                margin="dense"
                fullWidth
                placeholder="Name or Plot No Search"
                onChange={(e) => {
                  if (e.target.value === "") {
                    setRefresh(!refresh);
                    setOffset(0);
                  } else {
                    searchCustomer(e.target.value);
                  }
                }}
              />
            </Box> */}
          </Box>

          {/* 
          <Box mt={1} display={"flex"} alignItems={"center"} gap={1}>
            <Button
              variant="outlined"
              size="small"
              sx={{ borderRadius: "28px" }}
              onClick={() => setShowFilters(!showFilters)}
            >
              {showFilters ? "Close Filters" : "Add Filters"}
            </Button>
            {showFilters && (
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  alignItems: "center",
                }}
              >
                <TextField
                  size="small"
                  onChange={(e) => setStart(e.target.value)}
                  value={start}
                  label="Start"
                  type="date"
                  fullWidth
                />
                <TextField
                  size="small"
                  onChange={(e) => setEnd(e.target.value)}
                  value={end}
                  label="End"
                  type="date"
                  fullWidth
                />
                <FormControl
                  fullWidth
                  variant="standard"
                  margin="dense"
                  size="small"
                >
                  <InputLabel id="paymentMethod-label">
                    Payment Method
                  </InputLabel>
                  <Select
                    labelId="paymentMethod-label"
                    label="PaymentMethod"
                    onChange={(e) => {
                      setPayMethod(e.target.value);
                    }}
                    defaultValue={""}
                  >
                    <MenuItem value="Cash">Cash</MenuItem>
                    <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
                    <MenuItem value="Mobile Payment">Mobile Payment</MenuItem>
                    <MenuItem value="Cheque">Cheque</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            )}
          </Box> */}

          {isloading ? (
            <div>Loading...</div>
          ) : (
            <div style={{ width: "100%" }}>
              <DataGrid
                rows={data || []}
                columns={columns}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      dateBilled: false,
                      paymentMethod: false,
                    },
                  },
                }}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                pageSizeOptions={[5, 10, 25, 50, 100]}
                rowCount={data?.total ?? 0}
                paginationMode="server"
                loading={isloading}
                slots={{
                  toolbar: GridToolbar,
                }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
              />
            </div>
          )}
        </Box>
      </Paper>
    </Box>
  );
}
