import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import SingleParcel from "./SingleParcel";
import MapIcon from "@mui/icons-material/Map";
import InfoIcon from "@mui/icons-material/Info";
import dayjs from "dayjs";

export default function List() {
  const [data, setData] = useState({ rows: [], total: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [column, setColumn] = useState("plotNo");
  const [searchValue, setSearchValue] = useState("");
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [selectedParcel, setSelectedParcel] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [showMap, setShowMap] = useState({});

  useEffect(() => {
    if (searchValue === "") {
      fetchLandParcels();
    }
  }, [paginationModel.page, paginationModel.pageSize, searchValue]);

  const fetchLandParcels = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `/api/landparcels?offset=${
          paginationModel.page * paginationModel.pageSize
        }&limit=${paginationModel.pageSize}&includeAdminUnits=true`
      );
      const fetchedData = await response.json();
      if (response.ok) {
        const enhancedData = await enhanceData(fetchedData.data);
        console.log(enhancedData);

        setData({ rows: enhancedData, total: fetchedData.total });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const enhanceData = async (rowData) => {
    try {
      return await Promise.all(
        rowData.map(async (item) => {
          try {
            const response = await fetch(`/api/parcellink?parcelId=${item.id}`);
            if (!response.ok) {
              throw new Error(`Failed to fetch parcel links for id ${item.id}`);
            }
            const enhancedData = await response.json();

            if (enhancedData.data.length > 0) {
              return { ...item, ...enhancedData.data[0] };
            }
            return { ...item };
          } catch (error) {
            console.error(`Error fetching data for parcel ${item.id}:`, error);
            return { ...item }; // Return original item if enhancement fails
          }
        })
      );
    } catch (error) {
      console.error("Error enhancing data:", error);
      return rowData;
    }
  };

  const handleSearch = async (value) => {
    setSearchValue(value);
    if (value !== "") {
      try {
        setIsLoading(true);
        const response = await fetch(
          `/api/landparcels?offset=0&limit=${paginationModel.pageSize}&${column}=${value}&includeAdminUnits=true`
        );
        const fetchedData = await response.json();
        if (response.ok) {
          const enhancedData = await enhanceData(fetchedData.data);
          console.log(enhancedData);
          setData({ rows: enhancedData, total: fetchedData.total });
        }
      } catch (error) {
        console.error("Error searching data:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleRowClick = (params) => {
    setSelectedParcel(params?.row);
    setOpenDialog(true);
  };

  const toggleMap = (id) => {
    setShowMap((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const formatKey = (key) => {
    return key
      .split(" ")
      .map((part) =>
        part
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, (str) => str.toUpperCase())
          .replace(/Id$/, "ID")
      )
      .join(" - ");
  };

  const shouldSkipKey = (key) => {
    return ["geom", "coordinates", "type"].includes(key);
  };

  const renderValue = (value) => {
    if (value === null || value === undefined) return "-";
    if (typeof value === "boolean") return value ? "Yes" : "No";
    if (
      value instanceof Date ||
      (typeof value === "string" && value.match(/^\d{4}-\d{2}-\d{2}/))
    ) {
      return dayjs(value).format("DD/MM/YYYY HH:mm");
    }
    return value.toString();
  };

  const RecursiveTable = ({ data, level = 0 }) => {
    if (!data) return null;

    const sections = {
      "Plot Details": {
        fields: [
          "plotNo",
          "lrNo",
          "frNo",
          "type",
          "area",
          "createdAt",
          "updatedAt",
        ],
        data: data,
      },
      "Administrative Unit": {
        fields: ["name", "subCounty", "ward", "type", "latitude", "longitude"],
        data: data.AdminUnit,
      },
      "Valuation Details": {
        fields: [
          "srNo",
          "lrNo",
          "oldLrNo",
          "vbNo",
          "location",
          "size",
          "cgh",
          "private",
          "rate",
          "tenure",
          "use",
          "remarks",
          "plotNo",
        ],
        data:
          data.valuation || (data.landparcels && data.landparcels.valuation),
      },
      "Ownership Details": {
        fields: [
          "name",
          "gender",
          "ownershipType",
          "phoneNumber",
          "altPhoneNumber",
          "nationalId",
          "county",
          "subCounty",
          "ward",
          "physicalAddress",
          "postalAddress",
          "email",
        ],
        data: data.owners,
      },
    };

    return (
      <TableContainer component={Paper} sx={{ mb: level === 0 ? 2 : 0 }}>
        <Table size="small">
          <TableBody>
            {Object.entries(sections).map(([sectionName, section]) => {
              if (!section.data) return null;

              return (
                <>
                  <TableRow key={sectionName}>
                    <TableCell
                      colSpan={2}
                      sx={{
                        backgroundColor: "#f5f5f5",
                        fontWeight: "bold",
                        pl: 1,
                        py: 1.5,
                      }}
                    >
                      {sectionName}
                    </TableCell>
                  </TableRow>
                  {section.fields.map((field) => {
                    if (shouldSkipKey(field)) return null;
                    const value = section.data[field];
                    if (value === undefined) return null;

                    return (
                      <TableRow key={`${sectionName}-${field}`}>
                        <TableCell
                          sx={{
                            pl: 1,
                            width: "30%",
                            fontWeight: "normal",
                          }}
                        >
                          {formatKey(field)}
                        </TableCell>
                        <TableCell>{renderValue(value)}</TableCell>
                      </TableRow>
                    );
                  })}
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const columns = [
    {
      field: "index",
      width: 70,
      headerName: "No",
      renderCell: (params) => {
        const index = data
          ? data?.rows?.map((e) => e.id).indexOf(params?.row?.id)
          : 0;
        return (
          <Box display="flex" alignItems="center" height="100%">
            <Chip
              label={
                index + 1 + paginationModel.page * paginationModel.pageSize
              }
            />
          </Box>
        );
      },
    },
    {
      field: "geom",
      headerName: "Map",
      width: 100,
      renderCell: (params) => (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
          py={1}
        >
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              toggleMap(params.row.id);
            }}
          >
            <MapIcon color={showMap[params.row.id] ? "primary" : "inherit"} />
          </IconButton>
          {showMap[params.row.id] && (
            <SingleParcel geom={params?.value} area={params?.row?.area} />
          )}
        </Box>
      ),
    },
    {
      field: "plotNo",
      headerName: "Plot No",
      renderCell: (params) => (
        <Box display="flex" alignItems="center" height="100%">
          {params.value}
        </Box>
      ),
    },
    {
      field: "lrNo",
      headerName: "LR No",
      renderCell: (params) => (
        <Box display="flex" alignItems="center" height="100%">
          {params.value}
        </Box>
      ),
    },
    {
      field: "type",
      headerName: "Boundary",
      renderCell: (params) => (
        <Box display="flex" alignItems="center" height="100%">
          {params.value}
        </Box>
      ),
    },
    {
      field: "area",
      headerName: "Area",
      renderCell: (params) => (
        <Box display="flex" alignItems="center" height="100%">
          {params.value}
        </Box>
      ),
    },
    {
      field: "AdminUnit.subCounty",
      headerName: "SubCounty",
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" alignItems="center" height="100%">
          {params.row.AdminUnit?.subCounty || "-"}
        </Box>
      ),
    },
    {
      field: "AdminUnit.ward",
      headerName: "Ward",
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" alignItems="center" height="100%">
          {params.row.AdminUnit?.ward || "-"}
        </Box>
      ),
    },
    {
      field: "AdminUnit.name",
      headerName: "Town",
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" alignItems="center" height="100%">
          {params.row.AdminUnit?.name || "-"}
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (params) => (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            setSelectedParcel(params.row);
            setOpenDialog(true);
          }}
        >
          <InfoIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <Box display="flex" gap={2}>
        <Typography variant="h6" color="primary.light">
          Plots List
        </Typography>
        <Box flexGrow={1}></Box>
        <FormControl sx={{ minWidth: { xs: "100%", md: "150px" } }}>
          <InputLabel size="small">Search by...</InputLabel>
          <Select
            label="Search by..."
            size="small"
            onChange={(e) => setColumn(e.target.value)}
            value={column}
          >
            <MenuItem value="plotNo">Plot Number</MenuItem>
            <MenuItem value="frNo">FR Number</MenuItem>
            <MenuItem value="lrNo">LR Number</MenuItem>
            <MenuItem value="area">Area</MenuItem>
          </Select>
        </FormControl>
        <TextField
          variant="outlined"
          size="small"
          label="Search..."
          sx={{ minWidth: { xs: "100%", md: "150px" } }}
          value={searchValue}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </Box>
      <Divider sx={{ mt: 1 }} />
      <Box>
        <DataGrid
          rows={data.rows}
          columns={columns}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          rowCount={data.total}
          paginationMode="server"
          loading={isLoading}
          disableSelectionOnClick
          onRowClick={handleRowClick}
          autoHeight
          getRowHeight={() => "auto"}
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6">Parcel Details</Typography>
            <Button
              onClick={() => setOpenDialog(false)}
              color="inherit"
              size="small"
            >
              Close
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          {selectedParcel && (
            <>
              <Typography variant="h6" gutterBottom>
                Map View
              </Typography>
              <Divider sx={{ my: 1 }} />
              <SingleParcel
                geom={selectedParcel.geom}
                area={selectedParcel.area}
                height={200}
                width="100%"
              />

              <Typography variant="h6" sx={{ mt: 3, mb: 1 }}>
                Parcel Information
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <RecursiveTable data={selectedParcel} />
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
