import React, { useState, useEffect } from "react";
import {
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
} from "@mui/material";
import { Fill, Stroke, Style } from "ol/style";
import TemporaryLegend from "./TemporaryLegend";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";

const FilterAndStyleBar = ({
  legendItems,
  map,
  selectedLayer,
  setSelectedLayer,
  selectedColumn,
  setSelectedColumn,
  selectedClassification,
  setSelectedClassification,
}) => {
  const [columns, setColumns] = useState([]);
  const [generatedLegend, setGeneratedLegend] = useState([]);
  const [originalStyle, setOriginalStyle] = useState(null);

  useEffect(() => {
    if (selectedLayer && map) {
      const layer = map
        .getLayers()
        .getArray()
        .find((layer) => layer.get("title") === selectedLayer);

      if (layer) {
        const features = layer.getSource().getFeatures();
        if (features.length > 0) {
          const properties = features[0].getProperties();
          const layerColumns = Object.keys(properties).filter(
            (key) => key !== "geometry" && typeof properties[key] !== "object"
          );
          setColumns(layerColumns);
          // Store original style
        }
      }
    }
  }, [selectedLayer, map]);

  const updateLayerStyle = (layer, styleFunction) => {
    const source = layer.getSource();
    const layerTitle = layer.get("title");

    // Create new vector source
    const vectorSource = new VectorSource();

    // Get all features and apply new style to each
    const features = source.getFeatures().map((feature) => {
      const newFeature = feature?.clone();
      newFeature.setStyle(styleFunction(newFeature));
      return newFeature;
    });

    // Add features to new source
    vectorSource.addFeatures(features);

    // Create new layer
    const newLayer = new VectorLayer({
      source: vectorSource,
      title: layerTitle,
      style: styleFunction, // Set style function for future features
    });

    // Remove old layer and add new one
    map.removeLayer(layer);
    map.addLayer(newLayer);

    return newLayer;
  };

  const applyStyle = () => {
    if (!selectedLayer || !selectedColumn || !selectedClassification) return;

    const layer = map
      .getLayers()
      .getArray()
      .find((layer) => layer.get("title") === selectedLayer);

    if (!layer) return;

    const features = layer.getSource().getFeatures();
    const values = features
      .map((feature) => feature?.get(selectedColumn))
      .filter((value) => value !== undefined && value !== null);

    let newLegend = [];
    if (selectedClassification === "Unique Classification") {
      applyUniqueStyle(layer, values, newLegend);
    } else {
      applyIntervalStyle(layer, values, newLegend);
    }
    setGeneratedLegend(newLegend);
  };

  const applyUniqueStyle = (layer, values, legend) => {
    const uniqueValues = [...new Set(values)];
    const colors = [
      "#1f77b4",
      "#ff7f0e",
      "#2ca02c",
      "#d62728",
      "#9467bd",
      "#8c564b",
      "#e377c2",
      "#7f7f7f",
      "#bcbd22",
      "#17becf",
    ];

    const valueColorMap = {};
    uniqueValues.forEach((value, index) => {
      const color = colors[index % colors.length];
      valueColorMap[value] = color;
      legend.push({ label: value, color, visible: true });
    });

    const styleFunction = (feature) => {
      const value = feature?.get(selectedColumn);
      const legendItem = legend.find((item) => item.label === value);
      const color = legendItem?.visible ? valueColorMap[value] : "#cccccc";

      return new Style({
        fill: new Fill({ color: color + "80" }),
        stroke: new Stroke({ color, width: 2 }),
      });
    };

    updateLayerStyle(layer, styleFunction);
  };

  const applyIntervalStyle = (layer, values, legend) => {
    const numericValues = values.map((v) => Number(v)).filter((v) => !isNaN(v));
    const min = Math.min(...numericValues);
    const max = Math.max(...numericValues);
    const interval = (max - min) / 5;

    const colors = ["#edf8e9", "#bae4b3", "#74c476", "#31a354", "#006d2c"];

    // Create legend items
    for (let i = 0; i < 5; i++) {
      const minValue = min + i * interval;
      const maxValue = min + (i + 1) * interval;
      legend.push({
        label: `${minValue.toFixed(2)} - ${maxValue.toFixed(2)}`,
        color: colors[i],
        visible: true,
      });
    }

    const styleFunction = (feature) => {
      const value = Number(feature?.get(selectedColumn));
      let color = "#cccccc";

      if (!isNaN(value)) {
        const index = Math.min(
          Math.floor((value - min) / interval),
          colors.length - 1
        );
        const legendItem = legend[index];
        color = legendItem?.visible ? colors[index] : "#cccccc";
      }

      return new Style({
        fill: new Fill({ color: color + "80" }),
        stroke: new Stroke({ color: "#006d2c", width: 1 }),
      });
    };

    updateLayerStyle(layer, styleFunction);
  };

  const handleToggleLegendItem = (index) => {
    setGeneratedLegend((prevLegend) =>
      prevLegend.map((item, i) =>
        i === index ? { ...item, visible: !item.visible } : item
      )
    );

    const layer = map
      .getLayers()
      .getArray()
      .find((layer) => layer.get("title") === selectedLayer);

    if (layer) {
      const features = layer.getSource().getFeatures();
      const values = features
        .map((feature) => feature?.get(selectedColumn))
        .filter((value) => value !== undefined && value !== null);

      if (selectedClassification === "Unique Classification") {
        applyUniqueStyle(layer, values, generatedLegend);
      } else {
        applyIntervalStyle(layer, values, generatedLegend);
      }
    }
  };

  const resetStyle = () => {
    const layer = map
      .getLayers()
      .getArray()
      .find((layer) => layer.get("title") === selectedLayer);
    if (layer) {
      updateLayerStyle(layer, (feature) => {
        return new Style({
          fill: new Fill({ color: "rgba(13, 124, 102, 0.4)" }),
          stroke: new Stroke({ color: "#0D7C66", width: 1 }),
        });
      });
      setGeneratedLegend([]);
    }
  };

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: "0.5rem",
          left: "8.5rem",
          zIndex: 1000,
          backgroundColor: "white",
          padding: "12px",
          borderRadius: "4px",
          boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          minWidth: 300,
        }}
      >
        <FormControl size="small">
          <InputLabel>Layer</InputLabel>
          <Select
            value={selectedLayer}
            onChange={(e) => setSelectedLayer(e.target.value)}
          >
            {legendItems.map((item, index) => (
              <MenuItem key={index} value={item.label}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {columns.length > 0 && (
          <FormControl size="small">
            <InputLabel>Column</InputLabel>
            <Select
              value={selectedColumn}
              onChange={(e) => setSelectedColumn(e.target.value)}
            >
              {columns.map((column, index) => (
                <MenuItem key={index} value={column}>
                  {column}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {selectedColumn && (
          <FormControl size="small">
            <InputLabel>Classification</InputLabel>
            <Select
              value={selectedClassification}
              onChange={(e) => setSelectedClassification(e.target.value)}
            >
              <MenuItem value="Equal Interval">Equal Interval</MenuItem>
              <MenuItem value="Unique Classification">
                Unique Classification
              </MenuItem>
            </Select>
          </FormControl>
        )}

        <Box sx={{ display: "flex", gap: 1 }}>
          <Button
            variant="contained"
            onClick={applyStyle}
            disabled={
              !selectedLayer || !selectedColumn || !selectedClassification
            }
          >
            Apply Style
          </Button>
          <Button
            variant="outlined"
            onClick={resetStyle}
            disabled={!selectedLayer}
          >
            Reset
          </Button>
        </Box>
      </Box>

      {generatedLegend.length > 0 && (
        <TemporaryLegend
          legendItems={generatedLegend}
          onToggle={handleToggleLegendItem}
        />
      )}
    </>
  );
};

export default FilterAndStyleBar;
