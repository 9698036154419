import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import { BarChart, BarElement } from "@mui/x-charts";

const LandParcelsChart = () => {
  const [subCountyParcels, setSubCountyParcels] = useState([]);
  const [wardParcels, setWardParcels] = useState([]);
  const [townParcels, setTownParcels] = useState([]);
  const [xAxisP, setXAxisP] = useState([]);
  const [parcels, setParcels] = useState([]);
  const [selected, setSelected] = useState("subCounty");

  useEffect(() => {
    fetch("/api/landparcels/adminUnit/count/parcels")
      .then((response) => response.json())
      .then((data) => {
        const { subCountyParcels, wardParcels, townParcels } = data;

        setSubCountyParcels(subCountyParcels);
        setWardParcels(wardParcels);
        setTownParcels(townParcels);

        // Default to subCounty level data
        setXAxisP(subCountyParcels.map((item) => item.subCounty));
        setParcels(
          subCountyParcels.map((item) => parseInt(item.totalLandParcels))
        );
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleLevelChange = (e) => {
    const level = e.target.value;
    setSelected(level);

    let data, xAxisData;
    if (level === "subCounty") {
      data = subCountyParcels;
      xAxisData = subCountyParcels.map((item) => item.subCounty);
    } else if (level === "ward") {
      data = wardParcels;
      xAxisData = wardParcels.map((item) => item.ward);
    } else if (level === "town") {
      data = townParcels;
      xAxisData = townParcels.map((item) => item.town);
    }

    setXAxisP(xAxisData);
    setParcels(data.map((item) => parseInt(item.totalLandParcels)));
  };

  const generateColors = (length) => {
    const colors = [
      "#1f77b4",
      "#ff7f0e",
      "#2ca02c",
      "#d62728",
      "#9467bd",
      "#8c564b",
      "#e377c2",
      "#7f7f7f",
      "#bcbd22",
      "#17becf",
    ];
    return Array.from({ length }, (_, i) => colors[i % colors.length]);
  };

  return (
    <Card sx={{ borderRadius: "12px", boxShadow: "0px 10px 30px #60606040" }}>
      <CardContent>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h6">Plots</Typography>
          <Box>
            <Select
              value={selected}
              onChange={handleLevelChange}
              label="Level"
              size="small"
              sx={{ minWidth: "120px" }}
            >
              <MenuItem value="subCounty">Subcounty</MenuItem>
              <MenuItem value="ward">Ward</MenuItem>
              <MenuItem value="town">Town</MenuItem>
            </Select>
          </Box>
        </Box>
        <BarChart
          xAxis={[
            {
              data: xAxisP, // Bind the x-axis data dynamically based on selected level
              scaleType: "band",
              labels: false, // Remove bottom labels
            },
          ]}
          series={[
            {
              data: parcels, // Bind the parcels data dynamically based on selected level
              color: generateColors(parcels.length),
            },
          ]}
          slotProps={{
            legend: {
              itemMarkWidth: 12,
              itemMarkHeight: 12,
              labelStyle: {
                fontSize: 10,
              },
            },
          }}
          margin={{ top: 20, bottom: 20, left: 50, right: 20 }}
          height={300}
        >
          <BarElement color="#0F67B9" />
        </BarChart>
      </CardContent>
    </Card>
  );
};

export default LandParcelsChart;
