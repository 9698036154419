import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Box,
  Stack,
  Divider,
  Alert,
  Grid2,
} from "@mui/material";

export default function NewAgentDialog({ open, onClose }) {
  const [loading, setLoading] = useState(false);
  const [agentData, setAgentData] = useState({
    Name: "",
    Gender: "",
    Phone: "",
    NationalID: "",
    Email: "",
    Password: "",
    Confirm: "", // For password confirmation
    Status: true, // Default status is active
  });
  const [error, setError] = useState("");

  const handleChange = (e) => {
    setAgentData({
      ...agentData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    setError("");
    setLoading(true);

    // Simple validation
    if (
      !agentData.Name ||
      !agentData.Gender ||
      !agentData.Phone ||
      !agentData.NationalID ||
      !agentData.Email ||
      !agentData.Password ||
      !agentData.Confirm
    ) {
      setError("Please fill all required fields");
      setLoading(false);
      return;
    }

    if (agentData.Password !== agentData.Confirm) {
      setError("Passwords do not match");
      setLoading(false);
      return;
    }

    // Send data to the API to create a new Auth record
    fetch(`/api/auth`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Name: agentData.Name,
        Email: agentData.Email,
        Phone: agentData.Phone,
        NationalID: agentData.NationalID,
        Password: agentData.Password,
        Status: agentData.Status,
      }),
    })
      .then(async (res) => {
        if (res.ok) {
          // Check if the response content type is JSON
          const contentType = res.headers.get("Content-Type");
          if (contentType && contentType.includes("application/json")) {
            return res.json();
          } else {
            throw new Error("Unexpected response format");
          }
        } else {
          // Attempt to parse JSON error message if response is not OK
          const errorText = await res.text();
          try {
            const errorData = JSON.parse(errorText);
            throw new Error(
              errorData?.message || "Oops, something went wrong!"
            );
          } catch (parseError) {
            throw new Error(
              JSON.parse(errorText).message || "Oops, something went wrong!"
            );
          }
        }
      })
      .then(() => {
        setLoading(false);
        resetForm();
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message || "Failed to create agent");
      });
  };

  const resetForm = () => {
    setAgentData({
      Name: "",
      Gender: "",
      Phone: "",
      Email: "",
      Password: "",
      Confirm: "",
      Status: true,
    });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Create New Agent</DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing={2}>
          <TextField
            label="Name"
            name="Name"
            value={agentData.Name}
            onChange={handleChange}
            fullWidth
          />
          <Grid2 container spacing={2}>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <TextField
                label="National ID"
                name="NationalID"
                type="number"
                value={agentData.NationalID}
                onChange={handleChange}
                fullWidth
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <FormControl fullWidth>
                <InputLabel>Gender</InputLabel>
                <Select
                  name="Gender"
                  label="Gender"
                  value={agentData.Gender}
                  onChange={handleChange}
                  fullWidth
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                </Select>
              </FormControl>
            </Grid2>
          </Grid2>
          <Grid2 container spacing={2}>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <TextField
                label="Phone"
                name="Phone"
                type="number"
                value={agentData.Phone}
                onChange={handleChange}
                fullWidth
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <TextField
                label="Email"
                name="Email"
                type="email"
                value={agentData.Email}
                onChange={handleChange}
                fullWidth
              />
            </Grid2>
          </Grid2>
          <Grid2 container spacing={2}>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <TextField
                label="Password"
                name="Password"
                type="password"
                value={agentData.Password}
                onChange={handleChange}
                fullWidth
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <TextField
                label="Confirm Password"
                name="Confirm"
                type="password"
                value={agentData.Confirm}
                onChange={handleChange}
                fullWidth
              />
            </Grid2>
          </Grid2>
          {error ? (
            <Alert color="warning">{error}</Alert>
          ) : (
            <Box sx={{ height: "34px" }}></Box>
          )}
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={resetForm} variant="outlined" color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
