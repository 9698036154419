import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Divider,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Button,
  Chip,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

export default function Customers() {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [offset, setOffset] = useState(0);
  const [isloading, setIsLoading] = useState(false);

  const [editing, setEditing] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [filter, setFilter] = useState(false);
  const [column, setColumn] = useState("name");
  const [fcolumn, setFColumn] = useState(null);
  const [foperator, setFOperator] = useState(null);
  const [fvalue, setFValue] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  useEffect(() => {
    setIsLoading(true);
    fetch(
      `/api/ownerships?includeAdminUnits=true&offset=${
        paginationModel.page * paginationModel.pageSize
      }&limit=${paginationModel.pageSize}`
    )
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(false);
        setData(data);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }, [paginationModel.page, paginationModel.pageSize]);

  function searchCustomer(v) {
    setIsLoading(true);
    fetch(`/api/billing/search/${column}/${v}/${offset}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setIsLoading(false);
        if (data?.data?.length > 0) {
          console.log(data);
          setData(data);
        } else setData(null);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }

  const columns = [
    {
      field: "index",
      headerName: "SN",
      width: 70,
      renderCell: (params) => {
        const index = data.data.findIndex((e) => e.id === params?.row?.id);
        return (
          <Chip
            label={index + 1 + paginationModel.page * paginationModel.pageSize}
          />
        );
      },
    },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "phoneNumber", headerName: "Phone", flex: 1 },
    { field: "nationalId", headerName: "ID Number" },
    { field: "email", headerName: "Email" },
    { field: "gender", headerName: "Gender" },

    { field: "postalAddress", headerName: "Address" },
    {
      field: "action",
      headerName: "Action",
      renderCell: (params) => (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/bl/owners/${params.row.id}`);
          }}
          variant="outlined"
          size="small"
        >
          View
        </Button>
      ),
    },
  ];

  return (
    <Box>
      <Paper>
        <Box p={1}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Box>
              <Typography variant="h6">Land Owners</Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <FormControl fullWidth variant="standard" margin="dense">
                <InputLabel id="columns-label">Columns</InputLabel>
                <Select
                  labelId="columns-label"
                  label="columns"
                  onChange={(e) => {
                    console.log(e.target.value);
                    setColumn(e.target.value);
                  }}
                  defaultValue={""}
                >
                  <MenuItem value="name">Name</MenuItem>
                  <MenuItem value="gender">Gender</MenuItem>
                  <MenuItem value="phoneNumber">Phone Number</MenuItem>
                  <MenuItem value="ownershipType">Ownership Type</MenuItem>
                  <MenuItem value="phoneNumber">Phone No</MenuItem>
                  <MenuItem value="nationalId">National ID</MenuItem>
                  <MenuItem value="postalAddress">Postal Address</MenuItem>
                  <MenuItem value="email">Email</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="Search"
                variant="standard"
                margin="dense"
                fullWidth
                placeholder="Name or Plot No Search"
                onChange={(e) => {
                  if (e.target.value === "") {
                    setRefresh(!refresh);
                    setOffset(0);
                  } else {
                    searchCustomer(e.target.value);
                  }
                }}
              />
            </Box>
          </Box>
          <Divider />

          {isloading ? (
            <div>Loading...</div>
          ) : (
            <div style={{ width: "100%" }}>
              <DataGrid
                rows={data?.data || []}
                columns={columns}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      dateBilled: false,
                      paymentMethod: false,
                    },
                  },
                }}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                pageSizeOptions={[5, 10, 25, 50, 100]}
                rowCount={data?.total ?? 0}
                paginationMode="server"
                loading={isloading}
                onRowClick={(params) => navigate(`/bl/owners/${params.id}`)}
                slots={{
                  toolbar: GridToolbar,
                }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
              />
            </div>
          )}
        </Box>
      </Paper>
    </Box>
  );
}
