import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Chip,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { debounce } from "lodash";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function CSMessages() {
  const [data, setData] = useState(null);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [searchColumn, setSearchColumn] = useState("subject"); // Default search column
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  useEffect(() => {
    setLoading(true);
    fetch(
      `/api/messages?limit=${paginationModel.pageSize}&offset=${
        paginationModel.page * paginationModel.pageSize
      }`
    )
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        setData(data);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [paginationModel.page, paginationModel.pageSize]);

  const handleSearchChange = debounce((event) => {
    setSearchText(event.target.value);
    setPaginationModel((prev) => ({ ...prev, page: 0 }));
  }, 500);

  const handleRowClick = (message) => {
    setSelectedMessage(message);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedMessage(null);
  };

  const columns = [
    {
      field: "id",
      headerName: "SN",
      width: 70,
      renderCell: (params) => {
        const index = data?.data?.findIndex((e) => e.ID === params?.row?.ID);
        return (
          <Chip
            label={index + 1 + paginationModel.page * paginationModel.pageSize}
          />
        );
      },
    },
    {
      field: "userAdmin",
      headerName: "User/Admin",
      flex: 1,
      renderCell: (params) =>
        params.row.User
          ? `${params?.row?.User?.firstName} ${params?.row?.User?.lastName}`
          : `${params?.row?.Admin?.Name}`,
    },
    {
      field: "subject",
      headerName: "Subject",
      flex: 1,
    },
    {
      field: "message",
      headerName: "Content",
      flex: 2,
      renderCell: (params) => (
        <span
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {params?.row?.Message}
        </span>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <IconButton onClick={() => handleRowClick(params?.row)}>
          <ExpandMoreIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <Box
      padding={2}
      boxShadow="0px 10px 30px #60606040"
      borderRadius="12px"
      bgcolor="white"
    >
      <Box display="flex" alignItems="center" marginBottom={2}>
        <Typography variant="h5" flexGrow={1} gutterBottom>
          Activities
        </Typography>
        <FormControl size="small" sx={{ marginRight: 2, minWidth: 120 }}>
          <InputLabel>Search by</InputLabel>
          <Select
            value={searchColumn}
            onChange={(e) => setSearchColumn(e.target.value)}
            label="Search by"
          >
            <MenuItem value="subject">Subject</MenuItem>
            <MenuItem value="name">Name</MenuItem>
            <MenuItem value="message">Content</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Search..."
          variant="outlined"
          size="small"
          onChange={handleSearchChange}
        />
      </Box>

      {loading ? (
        <CircularProgress />
      ) : (
        <div style={{ width: "100%" }}>
          <DataGrid
            rows={data?.data || []}
            columns={columns}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            rowCount={data?.total}
            paginationMode="server"
            getRowId={(row) => row.ID}
            slots={{
              toolbar: GridToolbar,
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            onRowClick={(params) => handleRowClick(params.row)}
          />
        </div>
      )}

      {selectedMessage && (
        <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth>
          <DialogTitle>Message Details</DialogTitle>
          <DialogContent>
            <Typography variant="h6">
              {selectedMessage?.User
                ? `${selectedMessage?.User?.firstName} ${selectedMessage?.User?.lastName}`
                : `${selectedMessage?.Admin?.Name}`}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary" gutterBottom>
              {selectedMessage.Subject}
            </Typography>
            <Typography variant="body1" paragraph>
              {selectedMessage.Message}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Email:{" "}
              {selectedMessage?.User
                ? selectedMessage?.User?.email
                : selectedMessage?.Admin?.Email}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Phone:{" "}
              {selectedMessage?.User
                ? selectedMessage?.User?.phoneNumber
                : selectedMessage?.Admin?.Phone}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
}
