import React, { useEffect, useState } from "react";
import {
  Typography,
  CircularProgress,
  Box,
  Stack,
  Chip,
  Snackbar,
  Card,
  Button,
  Alert,
  Divider,
  Grid2,
} from "@mui/material";
import dayjs from "dayjs";
import {
  Call,
  ChildCare,
  ChildFriendly,
  ContactEmergency,
  Person,
} from "@mui/icons-material";

export default function UserDetails({ user, setRefresh, refresh }) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("success"); // "error", "warning", "info", "success"
  const [message, setMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleBeneficiaryCreated = () => {
    setRefresh(!refresh);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box pb={4}>
      <Stack>
        <Grid2 container spacing={2}>
          <Grid2
            size={{ xs: 12, md: 2 }}
            sx={{ display: "grid", placeContent: "center" }}
          >
            <Person fontSize="large" color="primary" />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 10 }}>
            <Typography
              variant="h6"
              color="primary.main"
              sx={{ margin: "auto" }}
              flexGrow={1}
            >
              {user?.name}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong style={{ fontWeight: 500 }}>Gender: </strong>{" "}
              {user?.phoneNumber}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong style={{ fontWeight: 500 }}>
                National ID/Passport No.:{" "}
              </strong>{" "}
              {user?.nationalId}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong style={{ fontWeight: 500 }}>Postal Address: </strong>{" "}
              {user?.postalAddress}
            </Typography>
          </Grid2>
          <Grid2 size={12}>
            <Typography variant="subtitle">Contact Details</Typography>
            <Divider />
          </Grid2>
          <Grid2
            size={{ xs: 12, md: 2 }}
            sx={{ display: "grid", placeContent: "center" }}
          >
            <Call fontSize="large" color="primary" />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 10 }}>
            <Typography variant="body2" gutterBottom>
              <strong style={{ fontWeight: 500 }}>Phone Number: </strong>{" "}
              {user?.phoneNumber}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong style={{ fontWeight: 500 }}>Alt Phone Number: </strong>{" "}
              {user?.altPhoneNumber}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong style={{ fontWeight: 500 }}>Email: </strong>{" "}
              {user?.nationalId}
            </Typography>
          </Grid2>
          <Grid2 size={12}>
            <Typography variant="subtitle">Location</Typography>
            <Divider />
          </Grid2>
          <Grid2
            size={{ xs: 12, md: 2 }}
            sx={{ display: "grid", placeContent: "center" }}
          >
            <Call fontSize="large" color="primary" />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 10 }}>
            <Typography variant="body2" gutterBottom>
              <strong style={{ fontWeight: 500 }}>County: </strong>{" "}
              {user?.county}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong style={{ fontWeight: 500 }}>SubCounty: </strong>{" "}
              {user?.subCounty}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong style={{ fontWeight: 500 }}>Ward: </strong> {user?.ward}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong style={{ fontWeight: 500 }}>Physical Address: </strong>{" "}
              {user?.physicalAddress}
            </Typography>
          </Grid2>
          <Grid2 size={12}>
            <Typography variant="subtitle">Beneficiaries</Typography>
            <Divider />
          </Grid2>
          <Grid2
            size={{ xs: 12, md: 2 }}
            sx={{ display: "grid", placeContent: "center" }}
          >
            <ChildCare fontSize="large" color="primary" />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 10 }}>
            {user?.beneficiaries.length > 0 ? (
              <>
                {user?.beneficiaries.map((user, index) => {
                  return (
                    <BeneficiaryCard
                      index={index}
                      user={user}
                      refresh={refresh}
                      setRefresh={setRefresh}
                    />
                  );
                })}
              </>
            ) : (
              <Box>
                <Typography>No beneficiaries</Typography>
              </Box>
            )}
          </Grid2>
        </Grid2>
      </Stack>
  
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={(event, reason) => {
          if (reason === "clickaway") {
            return;
          }
          setOpen(false);
        }}
      >
        <Alert severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

const BeneficiaryCard = ({ user, index, setRefresh, refresh }) => {
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this beneficiary?")) {
      try {
        setLoading(true);
        const response = await fetch(`/api/beneficiaries/${id}`, {
          method: "DELETE",
        });
        const body = await response.json();
        if (response.ok) {
          setError(body?.message);
          setTimeout(() => {
            setRefresh(!refresh);
          }, 1000);
        } else {
          setError(body?.message);
        }
      } catch (error) {
        setError("Error deleting valuation. Please try again.");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Card
        sx={{
          p: 3,
          boxShadow: "0px 4px 8px #60606040",
          borderRadius: 3,
          position: "relative",
        }}
      >
        <Chip
          sx={{ position: "absolute", top: 0, left: 0 }}
          label={index + 1}
        />
        <Typography
          variant="h6"
          color="primary.main"
          sx={{ margin: "auto" }}
          flexGrow={1}
        >
          {user?.name}
        </Typography>
        <Typography variant="body2" gutterBottom>
          <strong style={{ fontWeight: 500 }}>Gender: </strong>{" "}
          {user?.phoneNumber}
        </Typography>
        <Typography variant="body2" gutterBottom>
          <strong style={{ fontWeight: 500 }}>
            National ID/Passport No.:{" "}
          </strong>{" "}
          {user?.nationalId}
        </Typography>
        <Button
          onClick={() => {
            handleDelete(user?.id);
          }}
          disabled={loading}
          variant="outlined"
          color="error"
          sx={{ textTransform: "capitalize" }}
        >
          {loading ? "Deleting..." : "Delete"}
        </Button>
        <Chip
          sx={{ position: "absolute", top: "1em", right: "1em" }}
          label={user?.relationship}
        />
      </Card>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={(event, reason) => {
          if (reason === "clickaway") {
            return;
          }
          setOpen(false);
        }}
      >
        <Alert severity={"error"} sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

