import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Alert,
  Divider,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

export default function EnquiryDialog({ open, onClose, refresh, setRefresh }) {
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  // Reset form when dialog opens
  useEffect(() => {
    if (open) {
      setTitle("");
      setCategory("");
      setPhone("");
      setMessage("");
      setSelectedUser(null);
      setError("");
      setSuccess("");
    }
  }, [open]);

  const fetchUserSuggestions = (phone) => {
    if (!phone) return;
    fetch(`/api/ownerships?phoneNumber=${phone}&limit=2`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) =>
        res.ok ? res.json() : Promise.reject("Error fetching suggestions")
      )
      .then((data) => setSuggestions(data?.data || []))
      .catch(() => setSuggestions([]));
  };

  const handleSubmit = async () => {
    if (!title || !category || !phone || !message) {
      setError("All fields are required.");
      return;
    }

    const data = {
      title,
      category,
      phone,
      message,
      email: selectedUser ? selectedUser.email : null,
      name: selectedUser ? selectedUser.name : null,
      ownershipId: selectedUser ? selectedUser.id : null,
    };

    try {
      setLoading(true);
      setError("");
      setSuccess("");
      const response = await fetch("/api/enquiries", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        setSuccess("Enquiry created successfully!");
        setTitle("");
        setCategory("");
        setPhone("");
        setMessage("");
        setSelectedUser(null);
        setTimeout(() => {
          setRefresh(!refresh);
          onClose();
        }, 1000);
      } else throw new Error("Failed to create enquiry");
    } catch (err) {
      setError("Failed to create enquiry, please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle color="primary">Record an Enquiry</DialogTitle>
      <Divider />
      <DialogContent>
        <Box mb={2}>
          <TextField
            label="Search User by Phone Number"
            fullWidth
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
              fetchUserSuggestions(e.target.value);
            }}
            required
            sx={{ marginBottom: 2 }}
          />
          <List>
            {suggestions.map((user) => (
              <ListItem
                button
                key={user.id}
                onClick={() => {
                  setPhone(user.phoneNumber);
                  setSelectedUser(user);
                  setSuggestions([]);
                }}
              >
                <ListItemText
                  primary={`${user.name} - ${user.phoneNumber}`}
                  secondary={user.email}
                />
              </ListItem>
            ))}
          </List>
          <TextField
            label="Title"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            sx={{ marginBottom: 2 }}
          />
          <FormControl fullWidth sx={{ marginBottom: 2 }} required>
            <InputLabel>Category</InputLabel>
            <Select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <MenuItem value="General">General</MenuItem>
              <MenuItem value="Technical">Technical</MenuItem>
              <MenuItem value="Services">Services</MenuItem>
              <MenuItem value="Payment">Payment</MenuItem>
              <MenuItem value="Land Parcel">Land Parcel</MenuItem>
            </Select>
          </FormControl>

          <TextField
            label="Message"
            fullWidth
            multiline
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
        </Box>
        {error && <Alert severity="warning">{error}</Alert>}
        {success && <Alert severity="success">{success}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={loading}
        >
          {loading ? <CircularProgress size={20} /> : "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
