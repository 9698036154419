import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Stack,
  Divider,
  Alert,
  Grid2,
} from "@mui/material";

export default function NewUserDialog({ open, onClose }) {
  const roles = ["Super Admin", "Admin", "User", "Enumerator"];
  const departments = ["ICT", "Lands", "Finance", "Other"];
  const directorates = [
    "Land Management and Administration",
    "Physical Planning",
    "Housing Development",
    "Urban Development and Spatial Planning",
    "Budgeting and Expenditure Management",
    "Economic Planning and Development",
    "Public Finance Management",
    "Revenue Collection and Management",
    "ICT",
    "Other",
  ];
  const accessLevels = ["Full", "Restricted"];

  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    Name: "",
    Email: "",
    Phone: "",
    Position: "",
    Password: "",
    ConfirmPassword: "",
    Role: "User", // Default role
    Department: "ICT", // Default department
    Directorate: "ICT", // Default directorate
    AccessLevel: "Restricted", // Default access level
  });
  const [error, setError] = useState("");

  const handleChange = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    setError("");
    setLoading(true);

    // Validation: Check if Password and Confirm Password match
    if (userData.Password !== userData.ConfirmPassword) {
      setError("Passwords do not match!");
      setLoading(false);
      return;
    }

    // Simple validation
    if (
      !userData.Name ||
      !userData.Email ||
      !userData.Password ||
      !userData.Role ||
      !userData.Department ||
      !userData.Position ||
      !userData.Directorate ||
      !userData.AccessLevel
    ) {
      setError("Please fill all required fields");
      setLoading(false);
      return;
    }

    // Make POST request to /api/admin/register
    fetch(`/api/admin/register`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    })
      .then((res) => {
        if (!res.ok) {
          return res.json().then((data) => {
            throw new Error(data.message || "An error occurred");
          });
        }
        return res.json();
      })
      .then(() => {
        setLoading(false);
        resetForm();
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message || "Failed to create user");
      });
  };

  const resetForm = () => {
    setUserData({
      Name: "",
      Email: "",
      Phone: "",
      Position: "",
      Password: "",
      ConfirmPassword: "",
      Role: "User",
      Department: "ICT",
      Directorate: "ICT",
      AccessLevel: "Restricted",
    });
    setError("");
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Create New Admin</DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing={2}>
          <TextField
            label="Name"
            name="Name"
            value={userData.Name}
            onChange={handleChange}
            fullWidth
          />
          <Grid2 container spacing={2}>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <TextField
                label="Phone"
                name="Phone"
                value={userData.Phone}
                onChange={handleChange}
                fullWidth
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <TextField
                label="Position"
                name="Position"
                value={userData.Position}
                onChange={handleChange}
                fullWidth
              />
            </Grid2>
          </Grid2>
          <Grid2 container spacing={3}>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <FormControl fullWidth>
                <InputLabel>Department</InputLabel>
                <Select
                  name="Department"
                  label="Department"
                  value={userData.Department}
                  onChange={handleChange}
                  fullWidth
                >
                  {departments.map((department) => (
                    <MenuItem key={department} value={department}>
                      {department}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <FormControl fullWidth>
                <InputLabel>Directorate</InputLabel>
                <Select
                  name="Directorate"
                  label="Directorate"
                  value={userData.Directorate}
                  onChange={handleChange}
                  fullWidth
                >
                  {directorates.map((directorate) => (
                    <MenuItem key={directorate} value={directorate}>
                      {directorate}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid2>

            <Grid2 size={{ xs: 12, md: 6 }}>
              <FormControl fullWidth>
                <InputLabel>Role</InputLabel>
                <Select
                  name="Role"
                  label="Role"
                  value={userData.Role}
                  onChange={handleChange}
                  fullWidth
                >
                  {roles.map((role) => (
                    <MenuItem key={role} value={role}>
                      {role}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <FormControl fullWidth>
                <InputLabel>Access Level</InputLabel>
                <Select
                  name="AccessLevel"
                  label="Access Level"
                  value={userData.AccessLevel}
                  onChange={handleChange}
                  fullWidth
                >
                  {accessLevels.map((level) => (
                    <MenuItem key={level} value={level}>
                      {level}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid2>
          </Grid2>

          <TextField
            label="Email"
            name="Email"
            value={userData.Email}
            onChange={handleChange}
            fullWidth
          />
          <Grid2 container spacing={2}>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <TextField
                label="Password"
                name="Password"
                type="password"
                value={userData.Password}
                onChange={handleChange}
                fullWidth
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <TextField
                label="Confirm Password"
                name="ConfirmPassword"
                type="password"
                value={userData.ConfirmPassword}
                onChange={handleChange}
                fullWidth
              />
            </Grid2>
          </Grid2>
          {error && <Alert severity="error">{error}</Alert>}
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={resetForm} variant="outlined" color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
