import React, { useEffect, useState, useRef } from "react";
import { Map, View, Overlay } from "ol";
import "ol/ol.css";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { OSM } from "ol/source";
import { Vector as VectorSource } from "ol/source";
import { GeoJSON } from "ol/format";
import proj4 from "proj4";
import { register } from "ol/proj/proj4.js";
import Projection from "ol/proj/Projection.js";
import { Style, Fill, Stroke } from "ol/style";
import {
  Paper,
  Typography,
  CircularProgress,
  Divider,
  Grid2,
  Card,
} from "@mui/material";

// Register the custom projection
proj4.defs(
  "EPSG:21036",
  "+proj=utm +zone=36 +south +datum=WGS84 +units=m +no_defs"
);
register(proj4);

const generateHeatmapColor = (percentage) => {
  if (percentage >= 90) return "rgb(255, 0, 0)"; // Deep red
  if (percentage >= 70) return "rgb(255, 85, 85)"; // Lighter red
  if (percentage >= 50) return "rgb(255, 170, 85)"; // Orange
  if (percentage >= 30) return "rgb(255, 255, 85)"; // Yellow
  if (percentage > 0) return "rgb(170, 255, 85)"; // Light green
  return "rgb(85, 255, 85)"; // Successful green
};

// Add the legend component
const Legend = () => (
  <div
    style={{
      position: "absolute",
      bottom: "10px",
      left: "10px",
      backgroundColor: "white",
      padding: "10px",
      borderRadius: "8px",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    }}
  >
    <Typography variant="subtitle1" gutterBottom>
      Arrears %
    </Typography>
    <div>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}
      >
        <div
          style={{
            width: "20px",
            height: "20px",
            backgroundColor: "rgb(255, 0, 0)",
            marginRight: "5px",
          }}
        />
        <Typography variant="body2">90 - 100%</Typography>
      </div>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}
      >
        <div
          style={{
            width: "20px",
            height: "20px",
            backgroundColor: "rgb(255, 85, 85)",
            marginRight: "5px",
          }}
        />
        <Typography variant="body2">70 - 89</Typography>
      </div>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}
      >
        <div
          style={{
            width: "20px",
            height: "20px",
            backgroundColor: "rgb(255, 170, 85)",
            marginRight: "5px",
          }}
        />
        <Typography variant="body2">50 - 69</Typography>
      </div>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}
      >
        <div
          style={{
            width: "20px",
            height: "20px",
            backgroundColor: "rgb(255, 255, 85)",
            marginRight: "5px",
          }}
        />
        <Typography variant="body2">30 - 49</Typography>
      </div>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}
      >
        <div
          style={{
            width: "20px",
            height: "20px",
            backgroundColor: "rgb(170, 255, 85)",
            marginRight: "5px",
          }}
        />
        <Typography variant="body2">1 - 29</Typography>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            width: "20px",
            height: "20px",
            backgroundColor: "rgb(85, 255, 85)",
            marginRight: "5px",
          }}
        />
        <Typography variant="body2">0</Typography>
      </div>
    </div>
  </div>
);

const MultiPolygonMap = ({ id, url }) => {
  const [geoData, setGeoData] = useState(null);
  const [billingData, setBillingData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [map, setMap] = useState(null);
  const [popupContent, setPopupContent] = useState({
    total_landrent: null,
    total_landrate: null,
    total_arrears: null,
    total_billedamount: null,
    name: "",
  });
  const popupRef = useRef(null);

  // Fetch the GeoData
  const fetchGeoData = async () => {
    try {
      setLoading(true);
      const response = await fetch(url);
      if (!response.ok) throw new Error("Failed to fetch data");
      const data = await response.json();
      setGeoData(data);
    } catch (error) {
      console.error("Error fetching GeoData:", error);
    } finally {
      setLoading(false);
    }
  };

  // Initialize the map
  useEffect(() => {
    const projection = new Projection({
      code: "EPSG:21036",
      units: "m",
      extent: [-20037508, -20048966, 20037508, 20048966],
      worldExtent: [-180, -85, 180, 85],
    });

    const mapContainer = document.getElementById(id);

    const initialCenter = [644183, 9931469]; // Replace with appropriate EPSG:32736 coordinates

    const initialMap = new Map({
      target: mapContainer,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      view: new View({
        zoom: 10,
        projection: projection,
        center: initialCenter,
      }),
    });

    setMap(initialMap);

    return () => {
      initialMap.setTarget(null);
    };
  }, [id]);

  // Add GeoJSON data to the map once it's fetched
  useEffect(() => {
    if (geoData && map) {
      const vectorSource = new VectorSource({
        features: geoData.map((item) => {
          const arrears = item.total_arrears || 0;
          const billedAmount = item.total_billedamount || 1; // Avoid division by zero
          const percentage = (arrears / billedAmount) * 100;

          const multiPolygonFeature = new GeoJSON().readFeature({
            type: "Feature",
            geometry: {
              type: "MultiPolygon",
              coordinates: item.geom.coordinates,
            },
            properties: {
              name: item?.name || "Unknown",
              arrearsPercentage: percentage,
            },
          });
          return multiPolygonFeature;
        }),
      });

      const vectorLayer = new VectorLayer({
        source: vectorSource,
        style: (feature) => {
          const percentage = feature.get("arrearsPercentage");
          return new Style({
            fill: new Fill({
              color: generateHeatmapColor(percentage),
            }),
            stroke: new Stroke({
              color: "#888",
              width: 1,
            }),
          });
        },
      });

      map.addLayer(vectorLayer);
    }
  }, [geoData, map]);

  useEffect(() => {
    if (!popupRef.current) return;

    const overlay = new Overlay({
      element: popupRef.current,
      autoPan: true,
      autoPanAnimation: { duration: 250 },
    });

    if (map) {
      map.addOverlay(overlay);

      map.on("click", (event) => {
        const features = map.getFeaturesAtPixel(event.pixel);

        if (features && features.length > 0) {
          const feature = features[0];
          const properties = feature.getProperties(); // Extract properties
          const geometry = feature.getGeometry();
          let coordinates;

          if (geometry.getType() === "MultiPolygon") {
            const extent = geometry.getExtent();
            coordinates = [
              (extent[0] + extent[2]) / 2,
              (extent[1] + extent[3]) / 2,
            ];
          } else {
            coordinates = geometry.getCoordinates();
          }

          console.log(properties);
          setPopupContent({
            total_landrent: properties.total_landrent,
            total_landrate: properties.total_landrate,
            total_arrears: properties.total_arrears,
            total_billedamount: properties.total_billedamount,
            name: properties?.name || "Unknown",
          });

          overlay.setPosition(coordinates); // Show popup at correct position
        } else {
          overlay.setPosition(undefined); // Hide popup
          setPopupContent(null); // Clear popup content
        }
      });
    }

    return () => {
      map?.removeOverlay(overlay);
    };
  }, [map, popupRef.current]);

  useEffect(() => {
    setLoading(true);
    let isMounted = true;

    fetch(`${url}/${popupContent?.name || "Unknown"}`)
      .then((res) => res.json())
      .then((data) => {
        if (isMounted) {
          if (data && data.length > 0) {
            console.log(data[0]);
            setBillingData(data[0]);
          } else {
            setBillingData(null);
          }
          setLoading(false);
        }
      })
      .catch((error) => {
        if (isMounted) {
          console.error("Error fetching data:", error);
          setBillingData(null);
          setLoading(false);
        }
      });

    return () => {
      isMounted = false; // Cleanup on unmount
    };
  }, [popupContent?.name]);

  // Fetch the data when the component mounts
  useEffect(() => {
    fetchGeoData();
  }, []);

  return (
    <div style={{ position: "relative", width: "100%", height: "500px" }}>
      {loading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            zIndex: 1000,
          }}
        >
          <CircularProgress />
        </div>
      )}
      <div id={id} style={{ width: "100%", height: "100%" }} />
      <Legend />
      <Paper
        ref={popupRef}
        sx={{
          position: "absolute",
          backgroundColor: "white",
          padding: "10px",
          borderRadius: "8px",
          display: popupContent ? "block" : "none",
          zIndex: 10,
          minWidth: "25vw",
        }}
      >
        {popupContent && (
          <>
            <Typography variant="subtitle1">
              {popupContent?.name || "Unknown"} Billing Statistics
            </Typography>
            <Divider sx={{ mb: "5px" }} />
            <Grid2 container spacing={2} p={1}>
              <Grid2 size={{ xs: 12, md: 12 }}>
                <Card
                  sx={{
                    borderRadius: "8px",
                    p: 1,
                    boxShadow: "0px 4px 16px #60606030",
                    height: "100%",
                  }}
                >
                  <Divider sx={{ my: 1 }} />
                  <Typography variant="body2" gutterBottom>
                    <span style={{ color: "gray" }}>
                      Total Ground Rent: {billingData?.total_landrent || 0}
                    </span>
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <span style={{ color: "gray" }}>Total Land Rates: </span>
                    {billingData?.total_landrate || 0}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <span style={{ color: "gray" }}>
                      Total Billing Amount:{" "}
                    </span>
                    {billingData?.total_billedamount || 0}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <span style={{ color: "gray" }}>Total Arrears: </span>
                    {billingData?.total_arrears || 0}
                  </Typography>
                </Card>
              </Grid2>
            </Grid2>
          </>
        )}
      </Paper>
    </div>
  );
};

export default MultiPolygonMap;
