import React, { useState } from "react";
import { Box, ButtonGroup, Button, Card } from "@mui/material";
import Towns from "./Towns";
import List from "./List";
import MarketsMap from "./TownsMap";

export default function ParcelsInfo() {
  const [selected, setSelected] = useState("Towns");

  const handleNavigation = (path) => {
    setSelected(path);
  };

  return (
    <Box >
      <ButtonGroup aria-label="outlined primary button group">
        <Button
          variant={selected == "Towns" ? "contained" : "outlined"}
          sx={{ textTransform: "capitalize" }}
          onClick={() => handleNavigation("Towns")}
        >
          Towns
        </Button>
        <Button
          variant={selected == "Plots List" ? "contained" : "outlined"}
          sx={{ textTransform: "capitalize" }}
          onClick={() => handleNavigation("Plots List")}
        >
          Plots List
        </Button>
        <Button
          variant={selected == "Towns Map" ? "contained" : "outlined"}
          sx={{ textTransform: "capitalize" }}
          onClick={() => handleNavigation("Towns Map")}
        >
          Towns Map
        </Button>
      </ButtonGroup>
      <Box
        sx={{
          padding: "16px",
          boxShadow: "0px 4px 20px #60606030",
        }}
        component={Card}
      >
        {selected == "Towns" && <Towns />}
        {selected == "Plots List" && <List />}
        {selected == "Towns Map" && <MarketsMap />}
      </Box>
    </Box>
  );
}
