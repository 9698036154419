import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0F67B9", // Replace this with your desired primary color
      light: "#0F67B9",
      // #40679e FF6600
    },
    secondary: {
      main: "#F5A721", // Replace this with your desired primary color
    },
  },
});

export default theme;
